import CatergroryPage from "../components/AdminPanel/CatergroryPage";
import DashBoard from "../components/AdminPanel/DashBoard";
import UserDetail from "../components/AdminPanel/UserDetail";
// import BusinessUser from "../components/AdminPanel/BusinessUser";
import BusinessUserList from "../components/businessUser/List";
import BusinessUserAdd from "../components/businessUser/Add";
import Login from "../components/Login";
import Signup from "../components/Signup";
import UserView from "../components/AdminPanel/UserView";

const authProtectedRoutes = [
  // { path: "/compose-post", component: Compose },
  { path: "/catergorypage", component: CatergroryPage },  
  // {path:"/business_user", component:BusinessUser},
  {path:"/userpage", component:UserDetail},  
  {path:"/dashboard", component:DashBoard},
   
  { path: "/catergorypage", component: CatergroryPage },
  { path: "/business_user", component: BusinessUserList },
  { path: "/business_user/add", component: BusinessUserAdd },
  { path: "/business_user/:id", component: BusinessUserAdd },
  { path: "/userpage", component: UserDetail },
  { path: "/userpage/:id", component: UserView },
  { path: "/dashboard", component: DashBoard },
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/signup", component: Signup },
];

export { authProtectedRoutes, publicRoutes };
