import { BUActionTypes, BUInitialState, RegisterActionTypes } from "./businessUser.model";

const reducer = (state = BUInitialState, action) => {
  switch (action.type) {
    case BUActionTypes.USERLIST_REQUEST:
      return {
        ...state,
        businessUserListLoad: true,
      };

    case BUActionTypes.USERLIST_SUCCESS:
      var { payload } = action;
      console.log(payload, "Businessuser liost ");
      return {
        ...state,
        businessUserListLoad: false,
        businessUsersCount: payload?.data?.count,
        businessUsersList: payload?.data?.rows,
      };

    case BUActionTypes.USERLIST_FAILURE:
      return {
        ...state,
        businessUserListLoad: false,
      };
      case RegisterActionTypes.REGISTER_BUSINESS_REQUEST:
        return {
          ...state,
          isBusinessRegisterLoading: true,
          regSuccessLoading:false,
        };
  
      case RegisterActionTypes.REGISTER_BUSINESS_SUCCESS:    
      console.log(action?.payload,45354)  
        return {
          ...state,
          isBusinessRegisterLoading: false,
          businessId: action?.payload?.data?.business_id,
          regSuccessLoading:true,
          };
        
      case RegisterActionTypes.REGISTER_BUSINESS_FAILURE:
        return {
          ...state,
          isBusinessRegisterLoading: false,
          regSuccessLoading:false,
        };

    case BUActionTypes.RESET_REQUEST:
      return {
        ...state,
        businessUsersList: [],
        businessId: 0,
        businessUsersCount: 0,
        businessUserListLoad: false,
        isBusinessRegisterLoading: false,
        regSuccessLoading:false,
      };

      case BUActionTypes.DEACTIVATE_REQUEST:
        return {
          ...state,
          deactLoad: true,
          deActSucces:false
        };
  
      case BUActionTypes.DEACTIVATE_SUCCESS:
        return {
          ...state,
          deactLoad: false,
          deActSucces:true
        };
  
      case BUActionTypes.DEACTIVATE_FAILURE:
        return {
          ...state,
          deactLoad: false,
          deActSucces:false
        };
    default:
      return state;
  }
};

export { reducer as bUserReducer };
