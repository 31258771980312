import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const postBannerDetailApi = (formData) => {
  console.log(formData, "345rtfbyh");
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/banner/create", formData, Http.getAuthToken());
};

export const postSettingDetailApi = (formData) => {
  console.log(formData, "345rtfbyh");
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/settings/create", formData, Http.getAuthToken());
};

export const getSettingDetailApi = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/settings/", Http.getAuthToken());
}
