import React, { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CatAddSelector } from "./Selector";
import { getUserDataRequest, getUserOrderDataRequest } from "./Action";

import { TbMenuOrder } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import {
  adminSelector,
  deactivateRequest,
  userListRequest,
} from "../../store/admin/admin.action";
import { TablePagination } from "@mui/material";

const UserDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* Selectors */
  const { userData, userOrderData } = useSelector(CatAddSelector);
  const userdatas = useSelector((state) => state.AdminReducer?.userData);
  const { deActSuccess } = useSelector(adminSelector);
  console.log(userdatas, "userdatasuserdatas");
  console.log(deActSuccess, "67iuiuu");
  /* States */
  const [id, setId] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [pageSize, setPageSize] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  console.log(id, "sdfsdl454");
  /* Function */

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setRowsPerPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDeactivate = (val) => {
    console.log(val, "uhohoui");
    let stat = val?.status == 1 ? 0 : 1;
    const payload = {
      email: val?.email,
      status: stat,
    };
    dispatch(deactivateRequest(payload));
    console.log(payload, "567yuhjuy");
  };
  /* UseEffect */
  useEffect(() => {
    dispatch(userListRequest({ page, limit, query: "" }));
  }, [page, limit]);
  useEffect(() => {
    if (deActSuccess) {
      dispatch(userListRequest({ page, limit, query: "" }));
    }
  }, [deActSuccess, page, limit]);
  return (
    <div>
      <h2>User Data</h2>

      <Card>
        <Table bordered>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Email </th>
              <th>Phone No</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {userdatas?.rows?.map((ele, ind) => {
              return (
                <tr key={ele.id}>
                  <th scope="row">{++ind}</th>
                  <td>{ele.name ?? "No Data"} </td>
                  <td>{ele.email}</td>
                  <td>{ele.mobile ?? "No Data"}</td>
                  {/* <td> </td> */}
                  <td className="d-flex justify-content-center gap-5">
                    <Button
                      // onClick={()=> setId(ele.id)}
                      onClick={() => navigate("/userpage/" + ele.id)}
                    >
                      View / Edit
                    </Button>
                    <Button
                      variant={ele.status == 1 ? "danger" : "success"}
                      onClick={() => handleDeactivate(ele)}
                    >
                      {ele.status == 1 ? "Deactivate" : "Activate"}
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="d-flex justify-content-between px-2">
          <strong>Total count : {userdatas?.count}</strong>
          <TablePagination
            component="div"
            count={userdatas?.count}
            page={rowsPerPage}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Card>
    </div>
  );
};

export default UserDetail;
