import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card, Table, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { TbMenuOrder } from "react-icons/tb";
import { Link, useNavigate, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { useTranslation } from "react-i18next";
/** Internal Imports */
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { categorySelector } from "../../store/category/category.model";
/** Tab Contents */
import LinkDetail from "./LinkDetail";
import ImageUpload from "./ImageUpload";
import WorkingHours from "./WorkingHours";
import Amenties from "./Amenties";
import OfferDetail from "./OfferDetail";
import GeneralDetails from "./GeneralDetails";
import { linkGetRequest, reseDataRequest } from "../../store/bussiness/bussiness.action";
import { bussnessCatSelector } from "../../store/bussiness/bussiness.model";
import MenuPage from "./Menupage";

const Add = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    const { i18n, t } = useTranslation();
    /* Selectors */
    const { getBussinessDetails } = useSelector(bussnessCatSelector);
  const { categories } = useSelector(categorySelector);

    /* States */
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [getid, setGetId] = useState("");

    /* Function */
    const handleCategorySelect = (id) => {
        setGetId(parseInt(id));
        let category = categories?.data?.find((ele) => ele.id == id);
        console.log(category, "kdfskdsj");
        category.name === "Restaurants" ||
          category.name === "Hotel" ||
          category.name === "Hotels"
          ? setSelectedCategory(category)
          : setSelectedCategory(null);
      };
    const handlechange =()=>{
        dispatch(reseDataRequest())
    }
    /** Form Data */


    /* useEffect */
    useEffect(() => {
        console.log(id,"idfkhdgfdh")
        dispatch(linkGetRequest(id));
    }, [id]);
    return (
        <div>
            <Row>
                <Col md={6}>
                    <div className="page-title-container">
                        <h2>{t("business") + " " + t("user") + t("add")}</h2>
                    </div>
                </Col>
                <Col md={6}>
                    <Link to="/business_user">
                        <div className="text-end mb-2">
                            <Button variant="success" type="button" onClick={handlechange}>
                                {t("back")}
                            </Button>
                        </div>
                    </Link>
                </Col>
            </Row>
            <Card>
                <section>
                    <Container className="mt-4">
                        <div>
                            <Row>
                                <Col md={12} lg={12}>
                                    <Tabs
                                        defaultActiveKey="general"
                                        id="uncontrolled-tab-example"
                                        className="mb-3"
                                    >
                                        <Tab eventKey="general" title={t("general")}>
                                            <GeneralDetails propId={id} onSelectCategory={handleCategorySelect}  />
                                        </Tab>
                                        <Tab eventKey="link" title={t("link")}>
                                            <LinkDetail />
                                        </Tab>
                                        <Tab eventKey="image" title={t("image")}>
                                            <ImageUpload />
                                        </Tab>
                                        <Tab eventKey="working_times" title={t("working_times")}>
                                            <WorkingHours />
                                        </Tab>
                                        <Tab eventKey="amenities" title={t("amenities")}>
                                            <Amenties />
                                        </Tab>
                                        <Tab eventKey="offer_details" title={t("offer_details")}>
                                            <OfferDetail />
                                        </Tab>
                                        {selectedCategory &&(
                                             <Tab eventKey="menu" title={t("menu")}>
                                             <MenuPage getcatid={getid}/>
                                         </Tab>
                                        )}
                                       

                                    </Tabs>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
            </Card>
        </div>
    );
};

export default Add;
