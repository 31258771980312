import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { adminSelector, userListRequest } from "../../store/admin/admin.action";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_BASE } from "../../services/config";
import { userDataValidator } from "../../validators/Validators";

const UserView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { userData } = useSelector(adminSelector);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(userDataValidator),
  });

  useEffect(() => {
    dispatch(userListRequest({ page: "", limit: "", query: id }));
  }, [id, dispatch]);

  useEffect(() => {
    if (userData?.rows?.[0]) {
      const user = userData.rows[0];
      setValue("name", user.name);
      setValue("lastName", user.lastName);
      setValue("email", user.email);
      setValue("mobile", user.mobile);
      setValue("nationalId", user.nationalId);
      setValue("city", user.city);
      setValue("address", user.address);
      setValue("pin_code", user.pin_code);
    }
  }, [userData, setValue]);

  const onSubmit = (formdata) => {
    console.log(formdata, "uyiosdfkjf");
    
  };

  return (
    <div>
      <div>
        <div className="d-flex justify-content-between">
          <h1 className="text-muted">User Details</h1>
          <Button onClick={() => navigate("/userpage")}>
            Back
          </Button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mt-4">
            <Col sm={12}>
              <img
                src={
                  "http://192.168.0.107:4007/" +
                  userData?.rows?.[0]?.profile_imageurl
                }
                alt=""
              />
            </Col>
            <Col md={6}>
              <label htmlFor="name">First Name :</label>
              <div className="mb-3 filled form-group tooltip-end-top">
                <input
                  type="text"
                  id="name"
                  placeholder="Enter your First Name"
                  className="form-control"
                  {...register("name")}
                />
                {errors.name && <p className="text-danger">{errors.name.message}</p>}
              </div>
            </Col>
            <Col md={6}>
              <label htmlFor="lastName">Last Name :</label>
              <div className="mb-3 filled form-group tooltip-end-top">
                <input
                  type="text"
                  id="lastName"
                  placeholder="Enter your Last Name"
                  className="form-control"
                  {...register("lastName")}
                />
                {errors.lastName && <p className="text-danger">{errors.lastName.message}</p>}
              </div>
            </Col>
            <Col md={6}>
              <label htmlFor="email">Email :</label>
              <div className="mb-3 filled form-group tooltip-end-top">
                <input
                  type="text"
                  id="email"
                  placeholder="Enter your Email Id"
                  className="form-control"
                  {...register("email")}
                />
                {errors.email && <p className="text-danger">{errors.email.message}</p>}
              </div>
            </Col>
            <Col md={6}>
              <label htmlFor="mobile">Mobile :</label>
              <div className="mb-3 filled form-group tooltip-end-top">
                <input
                  type="text"
                  id="mobile"
                  placeholder="Enter your Mobile Number"
                  className="form-control"
                  {...register("mobile")}
                />
                {errors.mobile && <p className="text-danger">{errors.mobile.message}</p>}
              </div>
            </Col>
            <Col md={6}>
              <label htmlFor="nationalId">National ID :</label>
              <div className="mb-3 filled form-group tooltip-end-top">
                <input
                  type="text"
                  id="nationalId"
                  placeholder="Enter your National ID"
                  className="form-control"
                  {...register("nationalId")}
                />
                {errors.nationalId && <p className="text-danger">{errors.nationalId.message}</p>}
              </div>
            </Col>
            <Col md={6}>
              <label htmlFor="city">City :</label>
              <div className="mb-3 filled form-group tooltip-end-top">
                <input
                  type="text"
                  id="city"
                  placeholder="Enter your City"
                  className="form-control"
                  {...register("city")}
                />
                {errors.city && <p className="text-danger">{errors.city.message}</p>}
              </div>
            </Col>
            <Col md={6}>
              <label htmlFor="address">Address :</label>
              <div className="mb-3 filled form-group tooltip-end-top">
                <input
                  type="text"
                  id="address"
                  placeholder="Enter your Address"
                  className="form-control"
                  {...register("address")}
                />
                {errors.address && <p className="text-danger">{errors.address.message}</p>}
              </div>
            </Col>
            <Col md={6}>
              <label htmlFor="pin_code">Pincode :</label>
              <div className="mb-3 filled form-group tooltip-end-top">
                <input
                  type="text"
                  id="pin_code"
                  placeholder="Enter your Pincode"
                  className="form-control"
                  {...register("pin_code")}
                />
                {errors.pin_code && <p className="text-danger">{errors.pin_code.message}</p>}
              </div>
            </Col>
          </Row>
          <Col className="text-end px-5">
            <Button variant="success" type="submit">Submit</Button>
          </Col>
        </form>
      </div>
    </div>
  );
};

export default UserView;
