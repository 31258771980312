import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card, Table, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { TablePagination } from "@mui/material";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import {
  bUListRequest,
  bUserSelector,
  deactivateRequest,
} from "../../store/businessuser/businessUser.action";

const List = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* Selectors */
  const { businessUsersList, businessUsersCount, deActSucces } = useSelector(bUserSelector);

  /* State Management */
  const [state, setState] = useState({
    page: 1,
    limit: 10,
    query: "",
    rowsPerPage: 0
  });

  /* Function */
  const handleChangePage = (event, newPage) => {
    setState(prevState => ({
      ...prevState,
      page: newPage + 1,
      rowsPerPage: newPage
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setState(prevState => ({
      ...prevState,
      limit: parseInt(event.target.value, 10),
      page: 1
    }));
  };

  const handleFilterChange = (event) => {
    setState(prevState => ({
      ...prevState,
      query: event.target.value,
      page: 1
    }));
  };

  const handleDeactivate = (val) => {
    const stat = val?.status === 1 ? 0 : 1;
    const payload = {
      email: val?.email,
      status: stat
    };
    dispatch(deactivateRequest(payload));
  };

  /* UseEffect */
  useEffect(() => {
    const formdata = {
      page: state.page,
      limit: state.limit,
      query: state.query
    };
    dispatch(bUListRequest(formdata));
  }, [state.page, state.limit, state.query, dispatch]);

  useEffect(() => {
    if (deActSucces) {
      const formdata = {
        page: state.page,
        limit: state.limit,
        query: state.query
      };
      dispatch(bUListRequest(formdata));
    }
  }, [deActSucces, state.page, state.limit, state.query, dispatch]);

  return (
    <div>
      <Row>
        <Col md={6}>
          <div className="page-title-container">
            <h2>Business List</h2>
          </div>
        </Col>
        <Col md={6}>
          <div className="text-end mb-2">
            <Link to="/business_user/add/">
              <Button variant="success" type="button">
                <CsLineIcons icon="plus" />
                Add Business User
              </Button>
            </Link>
          </div>
          <Form.Control
            type="text"
            placeholder="Filter by name or email"
            value={state.query}
            onChange={handleFilterChange}
            className="mb-2"
          />
        </Col>
      </Row>
      <Card>
        <Table bordered>
          <thead>
            <tr>
              <th>S.No</th>
              <th>User Name</th>
              <th>Business Name</th>
              <th>Email</th>
              <th>Phone No</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {businessUsersList?.map((ele, ind) => (
              <tr key={ele.id}>
                <th scope="row">{ ++ind}</th>
                <td>{ele?.name}</td>
                <td>{ele.name_of_the_business ?? ""}</td>
                <td>{ele.email}</td>
                <td>{ele.mobile}</td>
                <td className="d-flex justify-content-center gap-5">
                  <Button onClick={() => navigate("/business_user/" + ele.id)}>
                    View / Edit
                  </Button>
                  <Button
                    variant={ele.status === 1 ? "danger" : "success"}
                    onClick={() => handleDeactivate(ele)}
                  >
                    {ele.status === 1 ? "Deactivate" : "Activate"}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="d-flex justify-content-between px-2">
          <strong>Total count : {businessUsersCount}</strong>
          <TablePagination
            component="div"
            count={businessUsersCount}
            page={state.rowsPerPage}
            onPageChange={handleChangePage}
            rowsPerPage={state.limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Card>
    </div>
  );
};

export default List;
