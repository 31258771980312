import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { signinValidator, signupValidator } from "../validators/Validators";
import { useDispatch, useSelector } from "react-redux";
import { loginUserRequest } from "../store/auth/auth.action";
import Swal from "sweetalert2";
import "../assets/css/responsive.css";
import {adminSigninRequest} from "../store/admin/admin.action"
import logo from "../assets/img/app_icon.png";

const Login = () => {
  const isAuth = localStorage.getItem("isAuthenticated");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(signinValidator),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { error } = useSelector((state) => state.AuthReducer);

  const hanldeSigin = (formData) => {
    console.log(formData, "rtfghbjn");
    dispatch(adminSigninRequest(formData));
  };

  useEffect(() => {
    if (isAuth) {
    navigate("/dashboard");
  }
  }, [isAuth]);

  useEffect(() => {
    if (error != "")
      Swal.fire({
        icon: "error",
        title: error,
      });
  }, [error]);

  useEffect(() => {
    document.body.classList.add("h-100");
    const root = document.getElementById("root");
    if (root) {
      root.classList.add("h-100");
    }
    return () => {
      document.body.classList.remove("h-100");
      if (root) {
        root.classList.remove("h-100");
      }
    };
  }, []);

  return (
    <>
      {/* Background Start */}
      <div className="fixed-background" />
      {/* Background End */}

      <div className="container-fluid p-0 h-100 position-relative login_pages">
        <div className="row g-0 h-100">
          {/* Left Side Start */}
          <div className="offset-0 col-12 d-none d-lg-flex offset-md-1 col-lg h-lg-100">
            <div className="min-h-100 d-flex align-items-center">
              <div className="w-100 w-lg-75 w-xxl-50">
                <div>
                  <div className="mb-15">
                    <div class="logo ">
                      <img src={logo} />
                    </div>
                    <div
                      className="logo-default h-100 mb-5"
                      style={{ width: "120px", minHeight: "90px" }}
                    />           
                  </div>                  
                </div>
              </div>
            </div>
          </div>
          {/* Left Side End */}

          {/* Right Side Start */}
          <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
            <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
              <div className="sw-lg-50 px-5">               
                <div className="mb-5">
                  <h2 className="cta-1 mb-0 text-primary">Welcome,</h2>
                  <h2 className="cta-1 text-primary">let's get started!</h2>
                </div>
                <div className="mb-5">
                  <p className="h6">Please use your credentials to login.</p>                 
                </div>
                <div>
                  <form
                    className="tooltip-end-bottom"
                    onSubmit={handleSubmit(hanldeSigin)}
                  >
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <input
                        type="text"
                        name="mail_id"
                        id="mail_id"
                        placeholder="Enter your Username"
                        {...register("email")}
                        className="form-control"
                      />
                      {errors?.email?.message && (
                        <div>
                          <p className="error text-danger">
                            {errors?.email?.message}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Enter your Password"
                        {...register("password")}
                        className="form-control"
                      />
                      {errors?.password?.message && (
                        <div>
                          <p className="error text-danger">
                            {errors?.password?.message}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="text-end">
                      <Button type="submit">Login</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* Right Side End */}
        </div>
      </div>
    </>
  );
};

export default Login;
