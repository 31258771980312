import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const signIn = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(postData, 734567);
  return Http.post(baseUrl + "/admin/signin", postData);
};

export const getUserList = ({ page, limit, query }) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/user/list?page=" + page + "&limit=" + limit + "&query=" + query,
    Http.getAuthToken()
  );
};

export const getCardStat = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/settings/card-stat", Http.getAuthToken());
};

export const getDashStat = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/settings/dashboard-stat", Http.getAuthToken());
};

export const deactivate = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/user/deactivate", payload, Http.getAuthToken());
};
