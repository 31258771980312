import { action } from "typesafe-actions";
import { settingActionType } from "./Model";

export const postBannerManageRequest = (req) =>
    action(settingActionType.POSTBANNERDETAIL_REQUEST, req);
  export const postBannerManageSuccess = (res) =>
    action(settingActionType.POSTBANNERDETAIL_SUCCESS, res);
  export const postBannerManageFailure = (err) =>
    action(settingActionType.POSTBANNERDETAIL_FAILURE, err);
  
  export const postSettingDataRequest = (req) =>
    action(settingActionType.POSTSETTINGDATA_REQUEST, req);
  export const postSettingDataSuccess = (res) =>
    action(settingActionType.POSTSETTINGDATA_SUCCESS, res);
  export const postSettingDataFailure = (err) =>
    action(settingActionType.POSTSETTINGDATA_FAILURE, err);
  
  export const getSettingDataRequest = (req) =>
    action(settingActionType.GETSETTINGDATA_REQUEST, req);
  export const getSettingDataSuccess = (res) =>
    action(settingActionType.GETSETTINGDATA_SUCCESS, res);
  export const getSettingDataFailure = (err) =>
    action(settingActionType.GETSETTINGDATA_FAILURE, err);
  