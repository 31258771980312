export const BUActionTypes = {
  USERLIST_REQUEST: "@@businessUser/USERLIST_REQUEST",
  USERLIST_SUCCESS: "@@businessUser/USERLIST_SUCCESS",
  USERLIST_FAILURE: "@@businessUser/USERLIST_FAILURE",
  RESET_REQUEST: "@@businessUser/RESET_REQUEST",

  DEACTIVATE_REQUEST: "@@businessUser/DEACTIVATE_REQUEST",
  DEACTIVATE_SUCCESS: "@@businessUser/DEACTIVATE_SUCCESS",
  DEACTIVATE_FAILURE: "@@businessUser/DEACTIVATE_FAILURE",

};
export const RegisterActionTypes = {
  REGISTER_BUSINESS_REQUEST: "@@business_details/REGISTER_BUSINESS_REQUEST",
  REGISTER_BUSINESS_SUCCESS: "@@business_details/REGISTER_BUSINESS_SUCCESS",
  REGISTER_BUSINESS_FAILURE: "@@business_details/REGISTER_BUSINESS_FAILURE",
};

export const BUInitialState = {
  businessUsersList: [],
  businessId: 0,
  businessUsersCount: 0,
  businessUserListLoad: false,
  isBusinessRegisterLoading: false,
  regSuccessLoading:false,
  deactLoad:false,
  deActSucces:false,
};    
