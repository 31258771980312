export const AdminActionTypes = {
  LOGIN_ADMIN_REQUEST: "@@admin/LOGIN_ADMIN_REQUEST",
  LOGIN_ADMIN_SUCCESS: "@@admin/LOGIN_ADMIN_SUCCESS",
  LOGIN_ADMIN_FAILURE: "@@admin/LOGIN_ADMIN_FAILURE",

  USERLIST_REQUEST: "@@admin/USERLIST_REQUEST",
  USERLIST_SUCCESS: "@@admin/USERLIST_SUCCESS",
  USERLIST_FAILURE: "@@admin/USERLIST_FAILURE",

  CARDSTAT_REQUEST: "@@admin/CARDSTAT_REQUEST",
  CARDSTAT_SUCESS: "@@admin/CARDSTAT_SUCESS",
  CARDSTAT_FAILURE: "@@admin/CARDSTAT_FAILURE",

  DASHSTAT_REQUEST: "@@admin/DASHSTAT_REQUEST",
  DASHSTAT_SUCESS: "@@admin/DASHSTAT_SUCESS",
  DASHSTAT_FAILURE: "@@admin/DASHSTAT_FAILURE",


  DEACTIVATE_REQUEST: "@@admin/DEACTIVATE_REQUEST",
  DEACTIVATE_SUCCESS: "@@admin/DEACTIVATE_SUCCESS",
  DEACTIVATE_FAILURE: "@@admin/DEACTIVATE_FAILURE",

  ADMIN_LOGOUT: "@@admin/ADMIN_LOGOUT",
};

export const adminInitialState = {
  admin: {},
  stats: {},
  userData: {},
  isLoading: false,
  cardStat: {},
  cardLoad: false,
  dashStats: [],
  deActLoad:false,
  deActSuccess:false,
};
