/** External Imports */
import {
  Col,
  Container,
  Row,
  Form,
  Input,
  Button,
  Table,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { BiSolidCommentEdit } from "react-icons/bi";
import { CiEdit } from "react-icons/ci";
import { useFormik } from "formik";
import { MenuDetailValidator } from "../../validators/Validators";
import { useDispatch, useSelector } from "react-redux";
import {
  postMenuListRequest,
  getMenuListRequest,
} from "../../store/bussiness/bussiness.action";
import { bussnessCatSelector } from "../../store/bussiness/bussiness.model";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { menuType } from "../../utils/Constants";
import { useParams } from "react-router-dom";

function MenuPage({ getcatid }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let arabicStatus = i18n.language == "ar";
  const { id } = useParams();

  const business_id = localStorage.getItem("id");

  /* Selectors */
  const { getMenuData } = useSelector(bussnessCatSelector);
  /* States */
  const [name, setName] = useState({
    name: "",
  });
  console.log(name.name, "namess");

  const [submenu, setSubMenu] = useState({
    main_item: "",
    food_name: "",
    price: "",
    food_type: 0,
  });
  console.log(submenu, "vasdf");
  const [artists, setArtists] = useState([]);
  const [totalitem, setTotalItem] = useState([]);
  const [langSwitch, setLangSwitch] = useState(1);

  /* Fucntion */
  const handleRemoveitem = (id, index) => {
    console.log(id, index, "detaild");
    setArtists((prev) => prev?.filter((e, ind) => ind != index));
    setTotalItem((prev) => prev?.filter((e, index) => index != id));
  };

  const handlesubmit = () => {
    let menuItem = {};
    menuItem.data = totalitem?.map((ele) => {
      return ele;
    });
    menuItem.category_id = getcatid;
    menuItem.business_id=parseInt(id)

    menuItem.language_type = langSwitch;
    if (menuItem?.data?.length == 0) {
      Swal.fire({
        title: arabicStatus ? "إضافة بيانات القائمة" : "Add Menu Data",
        text: arabicStatus ? "لا توجد بيانات للحفظ" : "No Data to Save",
        icon: "error",
      });
    } else {
      menuItem.arabicStatus = arabicStatus;
      dispatch(postMenuListRequest(menuItem));
    }
  };

  const addMainItem = () => {
    if (name?.name == " ") {
      Swal.fire({
        title: arabicStatus ? "إضافة البيانات الرئيسية" : "Add Main Data",
        text: arabicStatus ? "لا توجد بيانات للحفظ" : "No Data to Save",
        icon: "error",
      });
    } else {
      setArtists([...artists, name]);
      setName("");
    }
  };
  const addFoodItem = () => {
    if (submenu?.food_name == "") {
      Swal.fire({
        title: arabicStatus ? "إضافة بيانات الغذاء" : "Add Food Data",
        text: arabicStatus ? "لا توجد بيانات للحفظ" : "No Data to Save",
        icon: "error",
      });
    } else {
      setTotalItem([...totalitem, submenu]);
      setSubMenu("");
    }
  };

  /** useEffect */

  useEffect(() => {
    dispatch(getMenuListRequest(id));
  }, [id]);

  /** Form Data */
  const formik = useFormik({});
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  return (
    <div>
      <div className="add-listing-form details-listing-form form-space mb-60 wow fadeInUp ">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row>
            <Col md={6}>
              {" "}
              <h4 className="title">{t("menu") + " " + t("details")}</h4>
            </Col>
            <Col md={6}>
              <div className={`${arabicStatus ? "text-start" : "text-end"}`}>
                {langSwitch == 1 ? (
                  <Button
                    className="btn btn-primary"
                    type="Button"
                    onClick={() => setLangSwitch(2)}
                  >
                    اﻟﻌﺮﺑﻴﺔ
                  </Button>
                ) : (
                  <Button
                    className="btn btn-primary"
                    type="Button"
                    onClick={() => setLangSwitch(1)}
                  >
                    English
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          <h4 className="title">{t("main") + " " + t("item")}</h4>
          <Row className="mb-3">
            <Form.Group as={Col} lg={3} className="food-menu">
              <Form.Label className="label-style">
                {t("food") + " " + t("category")}
              </Form.Label>
              <Form.Control
                type="text"
                value={name.name}
                onChange={(e) => {
                  const { value } = e.target;
                  setName({
                    ...name,
                    name: value,
                  });
                }}
              />
            </Form.Group>
            <div className="resubmit-btn">
              <Form.Group as={Col} lg={1}>
                <Button
                  type="reset"
                  onClick={() => {
                    addMainItem();
                  }}
                  className="ti-check form-btn clr1 res-space"
                  variant="primary"
                >Add</Button>
              </Form.Group>
            </div>
          </Row>

          <Row className="menu-row-submit1">
            <Table striped className="menu-list-submit1">
              <thead>
                <tr>
                  <th>{t("food") + " " + t("category") + " " + t("list")} </th>
                </tr>
              </thead>
              <tbody>
                {artists &&
                  artists?.map((ele, index) => {
                    return (
                      <tr key={ele.id}>
                        <td>{ele.name}</td>
                        <td>
                          <Button variant="success">
                            <CiEdit className="" />
                          </Button>
                        </td>

                        <td>
                          <Button
                            className="ti-close"
                            variant="success"
                            onClick={(e) =>
                              handleRemoveitem(e.target.value, index)
                            }
                          ></Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Row>
          <div className="add-listing-form amenities-listing-form mb-60 wow fadeInUp">
            <h4 className="title">{t("food") + " " + t("item")}</h4>
            <Form.Group as={Col} lg={3} controlId="formGridState">
              <Form.Label className="label-style">
                {t("food") + " " + t("category")}
              </Form.Label>
              <Form.Select
                defaultValue={t("select")}
                value={submenu.main_item}
                onChange={(e) => {
                  const { value } = e.target;
                  setSubMenu({
                    ...submenu,
                    main_item: value,
                  });
                }}
              >
                <option>{t("select")}</option>
                {artists &&
                  artists?.map((ele) => {
                    return <option>{ele.name}</option>;
                  })}
              </Form.Select>
              <p className="text-danger">{errors.main_item}</p>
            </Form.Group>

            <Row className="mb-3">
              <Form.Group as={Col} lg={3} className="food-menu">
                <Form.Label className="label-style">
                  {t("food") + " " + t("name")}{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={submenu.food_name}
                  onChange={(e) => {
                    const { value } = e.target;
                    setSubMenu({
                      ...submenu,
                      food_name: value,
                    });
                  }}
                />
                <p className="text-danger">{errors.food_name}</p>
              </Form.Group>

              <Form.Group as={Col} lg={3}>
                <Form.Label className="label-style">
                  {t("price")}(SAR / ريال)
                </Form.Label>
                <Form.Control
                  type="text"
                  value={submenu.price}
                  onChange={(e) => {
                    const { value } = e.target;
                    setSubMenu({
                      ...submenu,
                      price: value,
                    });
                  }}
                />
                <p className="text-danger">{errors.price}</p>
              </Form.Group>

              <Form.Group as={Col} lg={3} controlId="formGridState">
                <Form.Label className="label-style">{t("type")}</Form.Label>
                <Form.Select
                  defaultValue={t("select")}
                  value={submenu.food_type}
                  onChange={(e) => {
                    const { value } = e.target;

                    setSubMenu({
                      ...submenu,
                      food_type: value,
                    });
                  }}
                >
                  <option>{t("select")}</option>
                  {menuType?.map((types) => {
                    return (
                      <option key={types.id} value={t(types.name)}>
                        {t(types.name)}
                      </option>
                    );
                  })}
                </Form.Select>
                <p className="text-danger">{errors.food_type}</p>
              </Form.Group>

              <div className="resubmit-btn">
                <Form.Group as={Col} lg={1}>
                  <Button
                    type="reset"
                    onClick={() => {
                      addFoodItem();
                    }}
                    className="ti-check form-btn clr1 res-space"
                    variant="primary"
                  >Add</Button>
                </Form.Group>
              </div>
            </Row>
            {/* Food Name Lists */}
            <Row className="menu-row-submit">
              <Table striped className="menu-list-submit">
                <thead>
                  <tr>
                    <th>{t("food") + " " + t("category")}</th>
                    <th>{t("food") + " " + t("name")}</th>
                    <th>{t("price")}</th>
                    <th>{t("type")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {totalitem &&
                    totalitem?.map((ele, ind) => {
                      let foodType = menuType.find((types) => {
                        return types.id == ele.food_type;
                      });
                      return (
                        <tr key={ele.id}>
                          <td>{ele.main_item}</td>
                          <td>{ele.food_name}</td>
                          <td>{ele.price}</td>
                          <td>{t(foodType?.name)}</td>
                          <td>
                            <Button
                              className="ti-close"
                              variant="primary"
                              onClick={(e) =>
                                handleRemoveitem(e.target.value, ind)
                              }
                            >Edit</Button>
                          </td>
                          <td>
                            <Button>
                              <CiEdit />
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <div class="Button text-end mt-2">
                <Button class="main-btn" type="submit" onClick={handlesubmit}>
                  {t("save") + " & " + t("submit")}
                </Button>
              </div>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default MenuPage;
