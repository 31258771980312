import { action } from "typesafe-actions";
import { AdminActionTypes } from "./admin.model";

export const adminSigninRequest = (req) =>
  action(AdminActionTypes.LOGIN_ADMIN_REQUEST, req);
export const adminSigninSuccess = (res) =>
  action(AdminActionTypes.LOGIN_ADMIN_SUCCESS, res);
export const adminSigninFailure = (err) =>
  action(AdminActionTypes.LOGIN_ADMIN_FAILURE, err);

export const userListRequest = (req) =>
  action(AdminActionTypes.USERLIST_REQUEST, req);
export const userListSuccess = (res) =>
  action(AdminActionTypes.USERLIST_SUCCESS, res);
export const userListFailure = (err) =>
  action(AdminActionTypes.USERLIST_FAILURE, err);

export const cardStatRequest = (req) =>
  action(AdminActionTypes.CARDSTAT_REQUEST, req);
export const cardStatSuccess = (res) =>
  action(AdminActionTypes.CARDSTAT_SUCESS, res);
export const cardStatFailure = (err) =>
  action(AdminActionTypes.CARDSTAT_FAILURE, err);

export const dashStatRequest = (req) =>
  action(AdminActionTypes.DASHSTAT_REQUEST, req);
export const dashStatSuccess = (res) =>
  action(AdminActionTypes.DASHSTAT_SUCESS, res);
export const dashStatFailure = (err) =>
  action(AdminActionTypes.DASHSTAT_FAILURE, err);

export const logout = () => action(AdminActionTypes.ADMIN_LOGOUT);

export const deactivateRequest = (req) =>
  action(AdminActionTypes.DEACTIVATE_REQUEST, req);
export const deactivateSuccess = (res) =>
  action(AdminActionTypes.DEACTIVATE_SUCCESS, res);
export const deactivateFailure = (err) =>
  action(AdminActionTypes.DEACTIVATE_FAILURE, err);

export const adminSelector = (state) => state.AdminReducer;
