import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  FormGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAnglesRight,
  faHippo,
  faLocationDot,
  faMagnifyingGlass,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useTranslation } from "react-i18next";

/** Internal Imports */
import { businessRegisterValidator } from "../../validators/Validators";
/** Selectors */
// import { getUserInfo } from "../../store/business_user/auth.selector";
// import { getUserAllInfo } from "../../store/business_user/auth.selector";

import { bussnessCatSelector } from "../../store/bussiness/bussiness.model";
/** API Calls */
import {
  categoryListRequest,
  subcategoryListRequest,
} from "../../store/category/category.action";
import {
  settingCountryRequest,
  settingLocationRequest,
  settingPlaceRequest,
  settingSelector,
  subLocationRequest,
} from "../../store/settings/settings.action";
import { categorySelector } from "../../store/category/category.model";
import { TagsInput } from "react-tag-input-component";
import {
  registerBusinessRequest,
  reseDataRequest,
  bUserSelector,
} from "../../store/businessuser/businessUser.action";
import Swal from "sweetalert2";
import { linkGetRequest } from "../../store/bussiness/bussiness.action";

function GeneralDetails({ propId , onSelectCategory}) {
  console.log(propId, "546tybh")
  const { i18n, t } = useTranslation();
  let arabicStatus = i18n.language == "ar";
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const arbicLanguage = (i18n.language == "ar");
  // const email = localStorage.getItem("email");

  /** Selectors */
  const { categories, subcaterory } = useSelector(categorySelector);
  const { getBussinessDetails } = useSelector(bussnessCatSelector);
  const { regSuccessLoading, businessId } = useSelector(bUserSelector);
  const { location, sub_location } = useSelector(settingSelector);
  console.log(getBussinessDetails, "34rdddtfgh")
  const [initialValues,setInitialValues]= useState({
    name: "",
    name_of_the_business: "",
    door_no: "",
    street: "",
    area: "",
    city: "",
    state: "",
    pin_code: "",
    about_business: "",
    keywords: "",
    mobile: "",
    email: "",
  });

  useEffect(()=>{
    console.log(getBussinessDetails,"kdhsjkghlsdg")
    setInitialValues({
      name: arbicLanguage
        ? getBussinessDetails?.general?.arabic_name
        : getBussinessDetails?.general?.name,
      mobile: getBussinessDetails?.general?.mobile
        ? getBussinessDetails?.general?.mobile
        : "",
      email: getBussinessDetails?.general?.email
        ? getBussinessDetails?.general?.email
        : "",
      name_of_the_business:
        getBussinessDetails?.general?.name_of_the_business != null
          ? getBussinessDetails?.general?.name_of_the_business
          : "",
      door_no: getBussinessDetails?.general?.door_no
        ? getBussinessDetails?.general?.door_no
        : "",
      street: getBussinessDetails?.general?.street
        ? getBussinessDetails?.general?.street
        : "",
      area: getBussinessDetails?.general?.area
        ? getBussinessDetails?.general?.area
        : "",
      city: getBussinessDetails?.general?.city
        ? getBussinessDetails?.general?.city
        : "",
      state: getBussinessDetails?.general?.state
        ? getBussinessDetails?.general?.state
        : "",
      pin_code: getBussinessDetails?.general?.pin_code
        ? getBussinessDetails?.general?.pin_code
        : "",
      about_business: getBussinessDetails?.general?.about_business
        ? getBussinessDetails?.general?.about_business
        : "",
      keywords: getBussinessDetails?.general?.keywords
        ? getBussinessDetails?.general?.keywords?.split(",")
        : "",
    });
  },[getBussinessDetails])
  /* FormData Init Value */
  
  /** States */
  const [isClicked, setIsClicked] = useState(false);
  const [isSubClicked, setIsSubClicked] = useState(false);
  const [categoryID, setCategoryID] = useState();
  const [subCategoryID, setsubCategoryID] = useState("");
  const [image, setImage] = useState();
  const [tags, setTags] = useState([]);
  const [cityId, setCityID] = useState();
  const [langSwitch, setLangSwitch] = useState(1);
  const [validated, setValidated] = useState(false);
  const [errorMsg, seterrorMsg] = useState({
    city: false,
    category: false,
    subCategory: false,
  });

  /* Function */
  const onSubmit = (formData) => {
    if (!cityId || !categoryID) {
      setValidated(false);
    } else {
      console.log(formData, 3454890567);
      formData.category_id = Number(categoryID);
      formData.sub_category_id = subCategoryID;
      formData.profile_imageurl = image;
      formData.keywords = tags.join(",");
      formData.city = cityId;
      formData.language_type = langSwitch;
      console.log(formData, 3489564);
      formData.arabicStatus = arabicStatus;
      dispatch(registerBusinessRequest({ formData }));
    }
  };

  const handleCategoryChange = (event) => {
    console.log(event.target.value, "erdd");

    setCategoryID(event.target.value);
    onSelectCategory(event.target.value);
    
  };
  const handleCityChange = (event) => {
    setCityID(event.target.value);
  };

  /** Form Data */
  const formik = useFormik({
    initialValues,
    enableReinitialize:true,
    validationSchema: businessRegisterValidator,
    onSubmit,
  });
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    resetForm,
    setValues,
  } = formik;

  console.log(errors, values, 456785);

  /* useEffect */
  useEffect(() => {
    setCategoryID(getBussinessDetails?.general?.category_id);
    setsubCategoryID(getBussinessDetails?.general?.sub_category_id);
    setTags(getBussinessDetails?.general?.keywords?.split(","));
    setCityID(getBussinessDetails?.general?.city);
  }, [getBussinessDetails.general, getBussinessDetails]);

  useEffect(() => {
    dispatch(categoryListRequest({}));
    dispatch(settingLocationRequest({}));
    dispatch(subcategoryListRequest({}));
    dispatch(subLocationRequest({}));
  }, []);

  useEffect(() => {
    console.log(id,"idfkhdgfdh")
    dispatch(linkGetRequest(id));
  }, [id]);


  useEffect(() => {
    console.log(values, 4674);
  }, [values]);

  useEffect(() => {
    if (arabicStatus) setLangSwitch(2);
    else setLangSwitch(1);
  }, [arabicStatus]);



  useEffect(() => {
    if (regSuccessLoading) {
      dispatch(reseDataRequest())
      navigate('/business_user/'+businessId);
      // dispatch(linkGetRequest(businessId));
      resetForm();
    }
  }, [regSuccessLoading])

  useEffect(() => {
    if (!propId) setTags([]);
  }, [propId]);

  console.log(errorMsg, 934758);
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Row className="justify-content-center mt-5 mx-2">
        <Col lg={12}>
          <div className="add-listing-form details-listing-form form-space wow fadeInUp">
            <Row className="mb-2">
              <Col md={6}>
                {" "}
                <h4 className="title">
                  {t("register") + " " + t("information")}
                </h4>
              </Col>
              <Col md={6}>
                <div className={`${arabicStatus ? "text-start" : "text-end"} `}>
                  {langSwitch == 1 ? (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => setLangSwitch(2)}
                    >
                      اﻟﻌﺮﺑﻴﺔ
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => setLangSwitch(1)}
                    >
                      English
                    </button>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group as={Col} lg={6} className="food-menu">
                  <Form.Label className="label-style">
                    {t("user") + " " + t("name")} :
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    dir={langSwitch == 1 ? "" : "rtl"}
                    placeholder={t("user") + " " + t("name")}
                    disabled={propId ? true : false}
                  />
                  {touched?.name && errors.name?.en && (
                    <p className="text-danger">
                      {arabicStatus ? errors.name.ar : errors.name.en}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group as={Col} lg={6} className="food-menu">
                  <Form.Label className="label-style">
                    {t("business") + " " + t("name")} :
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name_of_the_business"
                    value={values.name_of_the_business}
                    onChange={handleChange}
                    dir={langSwitch == 1 ? "" : "rtl"}
                    placeholder={
                      t("your") + " " + t("business") + " " + t("name")
                    }
                    disabled={propId ? true : false}
                  />
                  {touched?.name_of_the_business &&
                    errors.name_of_the_business?.en && (
                      <p className="text-danger">
                        {arabicStatus
                          ? errors.name_of_the_business?.ar
                          : errors.name_of_the_business?.en}
                      </p>
                    )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group as={Col} lg={6} className="food-menu">
                  <Form.Label className="label-style">
                    {t("mobile")} :
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={t("your") + " " + t("mobile")}
                    name="mobile"
                    onChange={handleChange}
                    value={values.mobile}
                    dir={langSwitch == 1 ? "" : "rtl"}
                    disabled={propId ? true : false}
                  />
                  {touched?.mobile && errors.mobile?.en && (
                    <p className="text-danger">
                      {arabicStatus ? errors.mobile?.ar : errors.mobile?.en}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group as={Col} lg={6} className="food-menu">
                  <Form.Label className="label-style">
                    {t("your") + " " + t("email")} :
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={t("your") + " " + t("email")}
                    name="email"
                    onChange={handleChange}
                    value={values?.email}
                    dir={langSwitch == 1 ? "" : "rtl"}
                    disabled={propId ? true : false}
                  />
                  {touched?.email && errors.email?.en && (
                    <p className="text-danger">
                      {arabicStatus ? errors.email?.ar : errors.email?.en}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group as={Col} lg={6} className="food-menu">
                  <Form.Label className="label-style">
                    {t("category")} :
                  </Form.Label>
                  <Form.Control
                    className="dn-space border-business"
                    as="select"
                    onChange={(e) => {
                      handleCategoryChange(e);
                    }}
                    defaultValue={categoryID}
                    placeholder={t("category")}
                    disabled={propId ? true : false}
                  >
                    <option value={0} key={0}>
                      {t("select")}
                    </option>
                    {categories?.data &&
                      categories?.data?.map((fields) => {
                        return (
                          <option value={fields.id} key={fields.id}>
                            {fields.name}
                          </option>
                        );
                      })}
                  </Form.Control>
                  {!categoryID && errorMsg?.category && (
                    <p className="text-danger">
                      {t("category") + " " + t("is") + " " + t("required")}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group as={Col} lg={6} className="food-menu">
                  <Form.Label className="label-style">
                    {t("sub_category")} :
                  </Form.Label>
                  <Form.Control
                    className="border-business"
                    as="select"
                    onChange={(e) => {
                      setsubCategoryID(e.target.value);
                    }}
                    defaultValue={subCategoryID}
                    name="sub_category"
                    placeholder={t("subcategory")}
                    disabled={propId ? true : false}
                  >
                    {!isSubClicked ? <option>{t("select")}</option> : ""}
                    {subcaterory?.data &&
                      subcaterory?.data
                        ?.filter((ele) => ele.category_id == categoryID)
                        .map((fields) => {
                          return (
                            <option value={fields.id}>{fields.name}</option>
                          );
                        })}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group as={Col} lg={6} className="food-menu">
                  <Form.Label className="label-style">
                    {t("door_no")} :
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("your") + " " + t("door_no")}
                    name="door_no"
                    value={values.door_no}
                    onChange={handleChange}
                    dir={langSwitch == 1 ? "" : "rtl"}
                    disabled={propId ? true : false}
                  />
                  {touched?.door_no && errors.door_no?.en && (
                    <p className="text-danger">
                      {arabicStatus ? errors.door_no?.ar : errors.door_no?.en}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group as={Col} lg={6} className="food-menu">
                  <Form.Label className="label-style">
                    {t("street")} :
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("your") + " " + t("street")}
                    name="street"
                    value={values.street}
                    onChange={handleChange}
                    dir={langSwitch == 1 ? "" : "rtl"}
                    disabled={propId ? true : false}
                  />
                  {touched?.street && errors.street?.en && (
                    <p className="text-danger">
                      {arabicStatus ? errors.street?.ar : errors.street?.en}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group as={Col} lg={6} className="food-menu">
                  <Form.Label className="label-style">
                    {t("your") + " " + t("area")} :
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("your") + " " + t("area")}
                    name="area"
                    value={values.area}
                    onChange={handleChange}
                    dir={langSwitch == 1 ? "" : "rtl"}
                    disabled={propId ? true : false}
                  />
                  {touched?.area && errors.area?.en && (
                    <p className="text-danger">
                      {arabicStatus ? errors.area?.ar : errors.area?.en}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group as={Col} lg={6} className="food-menu">
                  <Form.Label className="label-style">{t("city")} :</Form.Label>
                  <Form.Control
                    className="dn-space border-business"
                    as="select"
                    onChange={(e) => {
                      handleCityChange(e);
                    }}
                    value={cityId}
                    name="city"
                    placeholder={t("your") + " " + t("city")}
                    dir={langSwitch == 1 ? "" : "rtl"}
                    disabled={propId ? true : false}
                  >
                    {!isClicked ? (
                      <option>
                        {langSwitch == 2 ? "اختر مدينة" : "Select"}
                      </option>
                    ) : (
                      ""
                    )}
                    {sub_location?.rows &&
                      sub_location?.rows?.map((fields) => {
                        return (
                          <option value={fields.id} key={fields.id}>
                            {langSwitch == 2
                              ? fields.arabic_name ?? fields.name
                              : fields.name}
                          </option>
                        );
                      })}
                  </Form.Control>
                  {errorMsg?.city && (
                    <p className="text-danger">
                      {t("city") + " " + t("is") + " " + t("required")}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group as={Col} lg={6} className="food-menu">
                  <Form.Label className="label-style">
                    {t("your") + " " + t("state")} :
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("your") + " " + t("state")}
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                    dir={langSwitch == 1 ? "" : "rtl"}
                    disabled={propId ? true : false}
                  />
                  {touched?.state && errors.state?.en && (
                    <p className="text-danger">
                      {arabicStatus ? errors.state.ar : errors.state.en}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group as={Col} lg={6} className="food-menu">
                  <Form.Label className="label-style">
                    {t("pin_code")} :
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={t("your") + " " + t("pin_code")}
                    name="pin_code"
                    value={values.pin_code}
                    onChange={handleChange}
                    dir={langSwitch == 1 ? "" : "rtl"}
                    disabled={propId ? true : false}
                  />
                  {touched?.pin_code && errors.pin_code?.en && (
                    <p className="text-danger">
                      {arabicStatus ? errors.pin_code?.ar : errors.pin_code?.en}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="dn-space">
              <Col lg={6}>
                <Form.Group as={Col} lg={6} className="food-menu">
                  <Form.Label className="label-style">
                    {t("about") + "  " + t("business")} :{" "}
                  </Form.Label>
                  <Form.Control
                    className="border-business"
                    as="textarea"
                    name="about_business"
                    placeholder="About Your Bussiness"
                    value={values.about_business}
                    onChange={handleChange}
                    dir={langSwitch == 1 ? "" : "rtl"}
                    disabled={propId ? true : false}
                  />
                  {touched?.about_business && errors.about_business?.en && (
                    <p className="text-danger">
                      {arabicStatus
                        ? errors.about_business?.ar
                        : errors.about_business?.en}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group
                  as={Col}
                  lg={6}
                  className="food-menu tooltip-end-top filled"
                >
                  <Form.Label className="label-style">
                    {" "}
                    {t("business") + " " + t("logo")} :{" "}
                  </Form.Label>
                  <Form.Control
                    className="border-business"
                    type="file"
                    accept="image/*"
                    name="profile_img"
                    onChange={(e) => setImage(e.target.files[0])}
                    placeholderText=""
                    disabled={propId ? true : false}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group
                  as={Col}
                  lg={6}
                  className="food-menu tooltip-end-top filled border-tag rtl_class"
                >
                  <Form.Label className="label-style">
                    {" "}
                    {t("keywords")} :
                  </Form.Label>
                  <TagsInput
                    value={tags ?? []}
                    onChange={setTags}
                    name="keywords"
                    placeHolder={t("enter") + " " + t("keywords")}
                    disabled={propId ? true : false}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button
              variant="success"
              className="continue mx-2 mb-2 float-end"
              type="submit"
              onClick={() => {
                if (!cityId) {
                  seterrorMsg({
                    city: !cityId,
                    category: !categoryID,
                  });
                  setValidated(false);
                }
              }}
            >
              {t("save") + " & " + t("continue")}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default GeneralDetails;
