import { decryptdata } from "../../utils/encrypt&decrypt";
import { settingActionType, settingInitialState } from "./Model";

const reducer = (state = settingInitialState, action) => {
  switch (action.type) {
    case settingActionType.POSTBANNERDETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case settingActionType.POSTBANNERDETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case settingActionType.POSTBANNERDETAIL_FAILURE:
      return {
        ...state,
        loading: false,
      };
      case settingActionType.POSTSETTINGDATA_REQUEST:
      return {
        ...state,
        postLoading: true,
        sendsettingSuccess:false

      };
    case settingActionType.POSTSETTINGDATA_SUCCESS:
      return {
        ...state,
        postLoading: false,
        sendsettingSuccess:true
      };
    case settingActionType.POSTSETTINGDATA_FAILURE:
      return {
        ...state,
        postLoading: false,
        sendsettingSuccess:false

      };
      case settingActionType.GETSETTINGDATA_REQUEST:
      return {
        ...state,
        getLoading: true,
        getdataSuccess:false

      };
    case settingActionType.GETSETTINGDATA_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas3 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas3[0], "yhoioassdfd");
      return {
        ...state,
        getLoading: false,
        getSettingData:payloaddatas3[0],
        getdataSuccess:true
      }
    case settingActionType.GETSETTINGDATA_FAILURE:
      return {
        ...state,
        getLoading: false,
        getdataSuccess:false

      };
    default:
      return state;
  }
};

export { reducer as AdminSettingReducer };
