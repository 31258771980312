import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Form, Table, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
/** Internal Imports */
import { API_BASE } from "../../services/config";
import { bussnessCatSelector } from "../../store/bussiness/bussiness.model";
import {
  deleteAmentiesRequest,
  getAmentiesRequest,
  postAmentiesRequest,
  putAmentiesRequest,
} from "../../store/bussiness/bussiness.action";
import { bUserSelector } from "../../store/businessuser/businessUser.action";

function Amenties() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { i18n, t } = useTranslation();
  let arabicStatus = i18n.language == "ar";
  /* Selectors */
  const { getAmentiesData, amentisSuccess, updateAmentisLoading } =
    useSelector(bussnessCatSelector);
  const { businessId } = useSelector(bUserSelector);

console.log(getAmentiesData,348758)
  /* State */
  const [row, setRow] = useState([
    {
      amenities_name: "",
      image_url: "",
      language_type: "",
      business_id : parseInt(id)
    },
  ]);
  const [langSwitch, setLangSwitch] = useState(1);
  const [editingIndex, setEditingIndex] = useState(null);

  /* Function */

  const handlesubmit = () => {
    console.log(row[0].amenities_name, 23423)
    if (row[0].amenities_name != "") {
      if (editingIndex !== null) {
        const editedRow = getAmentiesData[editingIndex];
        editedRow.arabicStatus = arabicStatus
        
        dispatch(putAmentiesRequest({ editedRow, row }));
        setEditingIndex(null);
      } else {
        row.arabicStatus = arabicStatus
        row.business_id = parseInt(id)
        console.log(row,3457839745)
        dispatch(postAmentiesRequest(row));
      }
    } else {
      Swal.fire({
        title: arabicStatus ? "حفظ البيانات" : "No Data to Save",
        text: arabicStatus ? "لا توجد بيانات للحفظ" : "No Data to Save",
        icon: "error",
      });
    }

    setRow([
      {
        amenities_name: "",
        image_url: "",
        language_type: "",
      },
    ]);
  };

  const addRow = () => {
    setRow((prevRows) => [
      ...prevRows,
      {
        amenities_name: "",
        image_url: "",
        language_type: "",
      },
    ]);

    setEditingIndex(null);
  };

  const updateRow = (index, field, value) => {
    console.log(value, 4843);
    setRow((prevRows) =>
      prevRows.map((row, i) =>
        i === index
          ? { ...row, [field]: value, language_type: langSwitch }
          : row
      )
    );
  };

  const handleDeleteRow = (index) => {
    let formData = {}
    formData.id = getAmentiesData[index].id;
    formData.arabicStatus = arabicStatus
    dispatch(deleteAmentiesRequest(formData));
  };

  const handleEdit = (index) => {
    const editData = getAmentiesData[index];
    setRow([
      {
        amenities_name:
          editData.amenities_name == null
            ? editData.arabic_amenities_name
            : editData.amenities_name,
        image_url: editData.image_url,
      },
    ]);
    setEditingIndex(index);
  };

  /* UseEffect */
  useEffect(() => {
    if (amentisSuccess || updateAmentisLoading) {
      dispatch(getAmentiesRequest(id));
    }
  }, [id, amentisSuccess, updateAmentisLoading]);

  return (
    <div>
      <Row className="justify-content-center mt-5 mx-2">
        <Col lg={12}>
          <div className="add-listing-form details-listing-form form-space mb-60 wow fadeInUp">
            <Row>
              <Col md={6}>
                {" "}
                <h4 className="title">
                  {editingIndex !== null ? t("amenities") + " " + t("update") : t("amenities") + " " + t("add")}
                </h4>
              </Col>
              <Col md={6}>

                <div className={`${arabicStatus ? "text-start" : "text-end"}`}>
                  <Button variant="warning" className="continue mx-1" type="submit" onClick={addRow}>
                    {t("add")}
                  </Button>
                  {langSwitch == 1 ? (
                    <Button variant="primary" 
                      type="button"
                      onClick={() => setLangSwitch(2)}
                    >
                      اﻟﻌﺮﺑﻴﺔ
                    </Button>
                  ) : (
                    <Button variant="primary" 
                    type="button"
                      onClick={() => setLangSwitch(1)}
                    >
                      English
                    </Button>
                  )}

                </div>
              </Col>
            </Row>



            {row &&
              row?.map((amenitiData, index) => {
                return (
                  <Row>
                    <Col lg={6} key={index}>
                      <Form.Group as={Col} lg={6} className="food-menu">
                        <Form.Label className="label-style">
                          {t("amenities") + " " + t("name")} :
                        </Form.Label>
                        <Form.Control
                          type="text"
                          dir={langSwitch == 1 ? "" : "rtl"}
                          placeholder={t("amenities") + " " + t("name")}
                          value={amenitiData?.amenities_name}
                          onChange={(e) => {
                            updateRow(index, "amenities_name", e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} key={index}>
                      <Form.Group as={Col} lg={6} className="food-menu">
                        <Form.Label className="label-style">
                          {t("amenities") + " " + t("logo")}
                        </Form.Label>
                        <Form.Control
                          className="border-business"
                          type="file"
                          accept="image/*"
                          name="image_url"
                          onChange={(e) => {
                            updateRow(index, "image_url", e.target.files[0]);
                          }}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                );
              })}
            <Col lg={12}>
              <div className="button d-flex justify-content-end">
                {/* <button className="main-btn " type="button" onClick={handlesubmit}>
                  {editingIndex !== null ? t("amenities") + " " + t("update") : t("amenities") + " " + t("add")}
                </button> */}
                <Button variant="success" className="continue mx-2 mb-2 float-end" type="submit" onClick={handlesubmit}>
                  {editingIndex !== null ? t("amenities") + " " + t("update") : t("amenities") + " " + t("add")}
                </Button>
              </div>
            </Col>
          </div>
          <div className="add-listing-form details-listing-form form-space mb-60 wow fadeInUp">
            <h4 className="title">{t("amenities") + " " + t("list")}</h4>

            <Row>
              <Col lg={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>{t("s_no")}</th>
                      <th>{t("amenities") + " " + t("name")}</th>
                      <th>{t("images") + " " + t("preview")}</th>
                      <th>{t("action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getAmentiesData?.map((ele, ind) => (
                      <tr key={ele.id}>
                        <th scope="row">{ind + 1}</th>
                        <td>
                          {ele.amenities_name == null
                            ? ele.arabic_amenities_name
                            : ele.amenities_name}
                        </td>
                        <td>
                          <img
                            style={{ height: "50px" }}
                            src={API_BASE + ele.image_url}
                            alt=""
                          />
                        </td>
                        <td>
                          <Button variant="primary"
                            className="mx-2"
                            onClick={() => handleEdit(ind)}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </Button>
                          <Button
                          variant="danger"                            
                            onClick={() => handleDeleteRow(ind)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Amenties;
