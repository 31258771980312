import { action } from "typesafe-actions";
import { BUActionTypes, RegisterActionTypes } from "./businessUser.model";

export const bUListRequest = (req) =>
  action(BUActionTypes.USERLIST_REQUEST, req);
export const bUListSucess = (res) =>
  action(BUActionTypes.USERLIST_SUCCESS, res);
export const bUListFailure = (err) =>
  action(BUActionTypes.USERLIST_FAILURE, err);

export const reseDataRequest = (req) =>
  action(BUActionTypes.RESET_REQUEST, req);

export const registerBusinessRequest = (req) =>
  action(RegisterActionTypes.REGISTER_BUSINESS_REQUEST, req);
export const registerBusinessSuccess = (res) =>
  action(RegisterActionTypes.REGISTER_BUSINESS_SUCCESS, res);
export const registerBusinessFailure = (err) =>
  action(RegisterActionTypes.REGISTER_BUSINESS_FAILURE, err);

export const deactivateRequest = (req) =>
  action(BUActionTypes.DEACTIVATE_REQUEST, req);
export const deactivateSuccess = (res) =>
  action(BUActionTypes.DEACTIVATE_SUCCESS, res);
export const deactivateFailure = (req) =>
  action(BUActionTypes.DEACTIVATE_FAILURE, req);

export const bUserSelector = (state) => state.bUserReducer;
