/** External Imports */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Form, Input, Button } from "react-bootstrap";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { imageSendRequest } from "../../store/bussiness/bussiness.action";
import { useTranslation } from "react-i18next";
import { bussnessCatSelector } from "../../store/bussiness/bussiness.model";
import { bUserSelector } from "../../store/businessuser/businessUser.action";

/** Internal Imports */

function ImageUpload({ onNext }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { i18n, t } = useTranslation();
  let arabicStatus = i18n.language == "ar"
  const [errorMsg, setErrorMsg] = useState(false);
  const imageRef = useRef(null);
  /* Selectors */
  const { isImgLoading } = useSelector(bussnessCatSelector);
  const { businessId } = useSelector(bUserSelector);
console.log(businessId, "rghj")
  /* States */
  const [files, setFiles] = useState(null);
  console.log(files, "tyghbnj")
  /* Function */
  const handleUploadClick = () => {
    if (files?.length!=0) {
      const data={}
      data.files=files
      data.arabicStatus = arabicStatus
      data.business_id =parseInt(id)
      dispatch(imageSendRequest(data));
    }
    if (!files?.length) {
      setErrorMsg(!files);      
    }   
  };

  const handleBackgroundImageUpload = (event) => {

    setFiles(event.target.files)
    // setFiles(URL.createObjectURL(file));
  };
  const handleClickNext = () => {
    onNext();
  };
  /* FormData */

  /* UseEffect */
  useEffect(() => {

    if (imageRef.current && isImgLoading == true) {
      imageRef.current.value = null;
    }
  },[isImgLoading])
  return (
    <div>
      <Row className="justify-content-center mt-5 mx-2">
        <Col lg={12}>
          <div className="add-listing-form form-space  mb-60 wow fadeInUp">
            <h4 className={`${i18n.language == "ar" ? "arabic_title" : "title"}`}>{t("gallery") + " " + t("images")}</h4>

            <div className="filled form-group tooltip-end-top">
              <label className="label-style">{t("upload") + " " + t("images")}:</label>
              <Row>
                <Col lg={6}>
                  <Form.Control
                    className="border-business"
                    type="file"
                    accept="image/*"
                    name="image_url"
                    multiple
                    onChange={handleBackgroundImageUpload}
                  />
                </Col>
                <div className="slidingClass" id="sliderContainer">
                </div>
                {errorMsg && files == null &&
                  <p className="text-danger">{t("image_error")}</p>
                }
              </Row>
              <Button variant="success" className="continue mx-2 mb-2 float-end" type="submit" onClick={handleUploadClick}>
                {t("upload")}
              </Button>

            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ImageUpload;
