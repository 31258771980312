import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { UserActionTypes } from "./user/user.model";
import { AuthReducer } from "./auth/auth.reducer";
import { SettingReducer } from "./settings/settings.reducer";
import { UserReducer } from "./user/user.reducer";
import { menu } from "./menu/menu.reducer";
import { AuthActionTypes } from "./auth/auth.model";
import { CategoryAddReducer } from "../components/AdminPanel/Reducer";
import { AdminsettingSaga } from "../components/Setting/Saga";
import { AdminSettingReducer } from "../components/Setting/Reducer";
import { InventryReducer } from "../components/InventryScreen/Reducer";
import { AdminReducer } from "./admin/admin.reducer";
import { bUserReducer } from "./businessuser/businessUser.reducer";
import {CategoryReducer} from "./category/category.reducer"
import {BussCatReducer} from "./bussiness/bussiness.reducer"
const appReducer = combineReducers({
  AuthReducer,
  SettingReducer,
  UserReducer,
  menu,
  CategoryAddReducer,
  AdminSettingReducer,
  InventryReducer,
  AdminReducer,
  bUserReducer,
  CategoryReducer,
  BussCatReducer
});

const persistConfig = {
  key: "socialapp",
  storage: storageSession,
};
const rootReducer = (state, action) => {
  if (action.type === AuthActionTypes.CLEAR_USER_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
