import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, FormControl, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

/** Internal Imports */
import {
  postOfferDetailRequest,
  postSpecialDetailRequest,
  postDealDetailRequest,
} from "../../store/bussiness/bussiness.action";
import {
  businessOfferValidator,
  businessDealValidator,
} from "../../validators/Validators";
import { bUserSelector } from "../../store/businessuser/businessUser.action";
import { useParams } from "react-router-dom";

const OfferDetail = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let arabicStatus = i18n.language == "ar"
  const { id } = useParams();

  /** Selectors */
  const { businessId } = useSelector(bUserSelector);
  /** States */
  const [langSwitch, setLangSwitch] = useState(1);
  const [offerImage, setOfferImage] = useState();
  const [endDate, setEndDate] = useState();
  const [offerErrorMsg, setOfferErrorMsg] = useState({
    date: false,
    img: false,
  });
  const offerImgRef = useRef(null)
  const dealImgRef = useRef(null)
  /** For Deal */
  const [dealImage, setDealImage] = useState();
  const [dealEndDate, setDealEndDate] = useState();
  const [dealErrorMsg, setDealErrorMsg] = useState({
    date: false,
    img: false,
  });
  /** Initial Values */
  const initialValues = {
    offer: "",
    offer_desc: "",
  };

  const onSubmit = (formData) => {
    if (offerImage && endDate) {
      formData.offer_img = offerImage;
      formData.offer_end_date = endDate;
      formData.language_type = langSwitch;
      formData.business_id = parseInt(id);
      console.log(formData, 3937);
      if (endDate && offerImage) {        
        formData.arabicStatus = arabicStatus
        dispatch(postOfferDetailRequest(formData));

        resetForm();
        setEndDate();
        setOfferImage();
        if (offerImgRef.current) {
          offerImgRef.current.value = "";
        }
      }
    }
  };

  /** Form Data */
  const formik = useFormik({
    initialValues,
    validationSchema: businessOfferValidator,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors, resetForm } =
    formik;

  /** Deal Form */
  const dealValues = {
    deal: "",
    deal_desc: "",
  };

  const onDealSubmit = (formData) => {
    formData.deal_img = dealImage;
    formData.deal_end_date = dealEndDate;
    formData.language_type = langSwitch;
    formData.business_id = parseInt(id)
    console.log(dealEndDate, dealImage,54456)
    if (dealEndDate && dealImage) {
      formData.arabicStatus = arabicStatus
    console.log(formData, 4847);

      dispatch(postDealDetailRequest(formData));
      dealsetValues({
        deal: "",
        deal_desc: ""
      })
      dealFormReset();
      setDealEndDate();
      setDealImage();
      if (dealImgRef.current) {
        dealImgRef.current.value = "";
      }
    }
  };

  const formik2 = useFormik({
    initialValues: dealValues,
    validationSchema: businessDealValidator,
    onSubmit: onDealSubmit,
  });

  const {
    handleSubmit: handleDealSubmit,
    handleChange: handleDeal,
    values: newValues,
    touched: newtouched,
    errors: newerrors,
    resetForm: dealFormReset,
    setValues: dealsetValues,
  } = formik2;

  console.log(newValues, 3453453)
  return (
    <div>
      <Row className="justify-content-center mt-5 mx-2">
        <Col lg={12}>
          <div className="add-listing-form details-listing-form form-space mb-60 wow fadeInUp ">
            <Row>
              <Col md={6}>
                {" "}
                <h4 className="title">{t("offer") + " & " + t("deal") + " " + t("details")}</h4>
              </Col>
              <Col md={6}>
                <div className={`${arabicStatus ? "text-start" : "text-end"}`}>
                  {langSwitch == 1 ? (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => setLangSwitch(2)}
                    >
                      اﻟﻌﺮﺑﻴﺔ
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => setLangSwitch(1)}
                    >
                      English
                    </button>
                  )}
                </div>
              </Col>
            </Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Row>
                <Col md={12} lg={12}>
                  <h4 className="title">{t("offer") + " " + t("details")}</h4>
                  <Row>
                    <Col md={6} lg={6}>


                      <Form.Group controlId="input1" >
                        <Col md={6} lg={6}>
                          <Form.Label className="label-style">{t("offer") + " " + t("name")} :</Form.Label>
                          <Form.Control
                            className="border-business"
                            type="text"
                            placeholder={t("your") + " " + t("offer") + " " + t("name")}
                            name="offer"
                            value={values.offer}
                            onChange={handleChange}
                          />
                          <p className="text-danger">{arabicStatus ? errors.offer?.ar : errors.offer?.en}</p>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col md={6} lg={6}>

                      <Form.Group controlId="input1">
                        <Col md={6} lg={6}>
                          <Form.Label className="label-style">{t("offer") + " " + t("end") + " " + t("date")} :</Form.Label>
                          <DatePicker
                            className="date form-control"
                            name="offer_end_date"
                            placeholderText={t("offer") + " " + t("end") + " " + t("date")}
                            selected={endDate}
                            onChange={(date) => {
                              setEndDate(date);
                              setOfferErrorMsg({
                                date: false,
                              });
                            }}
                            minDate={new Date()}
                          />
                          {offerErrorMsg?.date && (
                            <p className="text-danger">{t("offer") + " " + t("end") + " " + t("date") + " " + t("is") + " " + t("required")}</p>
                          )}
                        </Col>
                      </Form.Group>
                    </Col>

                  </Row>
                  <Row>
                    <Col md={6} lg={6}>
                      <Form.Group controlId="input1">
                        <Col md={6} lg={6}>
                          <Form.Label className="label-style">{t("offer") + " " + t("description")} :</Form.Label>
                          <Form.Control
                            className="border-business"
                            as="textarea"
                            name="offer_desc"
                            placeholder={t("offer") + " " + t("description")}
                            value={values.offer_desc}
                            onChange={handleChange}
                          />
                          <p className="text-danger">{touched.offer_desc && arabicStatus ? errors.offer_desc?.ar : errors.offer_desc?.en}</p>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col md={6} lg={6}>
                      <Form.Group controlId="input1">
                        <Col md={6} lg={6}>
                          <Form.Label className="label-style">{t("offer") + " " + t("image")} :</Form.Label>
                          <Form.Control
                            className="border-business"
                            type="file"
                            accept="image/*"
                            name="offer_img"
                            onChange={(e) => {
                              setOfferImage(e.target.files[0]);
                              setOfferErrorMsg({
                                img: false,
                              });
                            }}
                            ref={offerImgRef}
                          />
                          {offerErrorMsg?.img && (
                            <p className="text-danger">{t("offer") + " " + t("image") + " " + t("is") + " " + t("required")} </p>
                          )}
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    variant="success"
                    className="main-btn mt-2 float-end "
                    type="submit"
                    onClick={() => {
                      if (!endDate || !offerImage) {
                        setOfferErrorMsg({
                          date: !endDate,
                          img: !offerImage,
                        });
                      } else {
                        setOfferErrorMsg({
                          date: !endDate,
                          img: !offerImage,
                        });
                      }
                    }}
                  >
                    {t("submit")}
                  </Button>
                </Col>
              </Row>
            </Form>
            <hr className="mt-5" />
            {/* Deal Form */}
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleDealSubmit();
              }}
            >
              <Row>
                <Col md={12}>
                  <h4 className="title">{t("deal") + " " + t("details")}</h4>
                  <Row>
                    <Col md={6} lg={6}>
                      <Form.Group controlId="input1">
                        <Col md={6} lg={6}>
                          <Form.Label className="label-style">
                            {t("last") + " " + t("minute") + " " + t("deal") + t("name")}:
                          </Form.Label>
                          <div class="form_group">
                            <Form.Control
                              className="border-business"

                              placeholder={t("your") + " " + t("deal") + t("name")}
                              name="deal"
                              value={newValues.deal}
                              onChange={handleDeal}
                            />
                            <p className="text-danger">{arabicStatus ? newerrors?.deal?.ar : newerrors?.deal?.en}</p>
                          </div>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col md={6} lg={6}>
                      <Form.Group controlId="input1" >
                        <Col md={6} lg={6}>
                          <Form.Label className="label-style">{t("deal") + " " + t("end") + " " + t("date")} :</Form.Label>
                          <DatePicker
                            className="date form-control"
                            name="deal_end_date"
                            placeholderText={t("deal") + " " + t("end") + " " + t("date")}
                            selected={dealEndDate}
                            onChange={(date) => {
                              setDealEndDate(date);
                              setDealErrorMsg({
                                date: false,
                              });
                            }}
                            minDate={new Date()}
                          />
                          {offerErrorMsg?.date && (
                            <p className="text-danger">{t("deal") + " " + t("end") + " " + t("date") + " " + t("is") + " " + t("required")}</p>
                          )}
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} lg={6}>
                      <Form.Group controlId="input1" >
                        <Col md={6} lg={6}>
                          <Form.Label className="label-style">{t("deal") + " " + t("description")} :</Form.Label>
                          <Form.Control
                            className="border-business"
                            as="textarea"
                            name="deal_desc"
                            placeholder={t("deal") + " " + t("description")}
                            value={newValues.deal_desc}
                            onChange={handleDeal}
                          />
                          <p className="text-danger">{arabicStatus ? newerrors?.deal_desc?.ar : newerrors?.deal_desc?.en}</p>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col md={6} lg={6}>
                      <Form.Group controlId="input1" >
                        <Col md={6} lg={6}>
                          <Form.Label className="label-style">{t("deal") + " " + t("image")} :</Form.Label>
                          <Form.Control
                            className="border-business"
                            type="file"
                            accept="image/*"
                            name="deal_img"
                            onChange={(e) => {
                              setDealImage(e.target.files[0]);
                              setDealErrorMsg({
                                img: false,
                              });
                            }}
                            ref={dealImgRef}
                          />
                          {console.log(dealErrorMsg, 349573475)}
                          {dealErrorMsg?.img && (
                            <p className="text-danger">{t("deal") + " " + t("image") + " " + t("is") + " " + t("required")}</p>
                          )}
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    variant="success"
                    className="float-end"
                    type="submit"
                    onClick={() => {
                      if (!dealEndDate || !dealImage) {
                        setDealErrorMsg({
                          date: !dealEndDate,
                          img: !dealImage,
                        });
                      } else {
                        setDealErrorMsg({
                          date: !dealEndDate,
                          img: !dealImage,
                        });
                      }
                    }}
                  >
                    {t("submit")}
                  </Button>
                </Col>
              </Row>
            </Form>
            <hr className="mt-5" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OfferDetail;
