import * as Yup from "yup";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

export const signupValidator = Yup.object().shape({
  name:Yup.string().required("Name is Required"),
  email: Yup.string().required("Mail is Required"),
  password: Yup.string()
    .matches(passwordRules, {
      message:
        "Please create a stronger password(min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit)",
    })
    .required("Required"),
});
export const signinValidator = Yup.object().shape({
  email: Yup.string().required("Mail is Required"),
  password: Yup.string().required("Required"),
});
export const AddGroupValidator = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
});

export const LinkedInValidator = Yup.object().shape({
  social_access_token: Yup.string().required("AccessToken is Required"),
  provided_user_id: Yup.string().required("Organisation Id is Required"),
});

export const profileSettingValidator = Yup.object().shape({
  company_name: Yup.string().required("Company Name is Required"),
  contact_number: Yup.string().required("Contact Number is Required"),
  mail_id: Yup.string().required("Mail id is Required"),
});

export const AccountSettingValidator = Yup.object().shape({
  group_id: Yup.number().required("Group is Required"),
});


export const businesssigninValidator = Yup.object().shape({
  email: Yup.string().email().required({en:"Email is Required",ar:"البريد الالكتروني مطلوب"}),
  otp: Yup.string().required({en:"OTP is Required",ar:"مطلوب مكتب المدعي العام"}),
});

export const businesssotpValidator = Yup.object().shape({
  email: Yup.string().required({en:"Email is Required",ar:"البريد الالكتروني مطلوب"}),
});

/** Business Register Validator */
export const businessRegisterValidator = Yup.object().shape({
  name: Yup.string().required({en:"User Name is Required",ar:"اسم المستخدم مطلوب"}),
  name_of_the_business: Yup.string().required({en:"Business Name is Required", ar: "الاسم التجاري مطلوب"}),
  email: Yup.string().email().required({en:"Email is Required", ar: "البريد الالكتروني مطلوب"}),
  mobile: Yup.string().required({en:"Mobile is Required", ar: "الجوال مطلوب"}),
  door_no: Yup.string().required({en:"Door No is Required", ar: "رقم الباب مطلوب"}),
  street : Yup.string().required({en:"State No is Required",ar:"الدولة رقم مطلوب"}),
  area: Yup.string().required({en:"Area is Required", ar:"المنطقة مطلوبة"}),  
  state: Yup.string().required({en:"State is Required", ar: "الدولة مطلوبة"}),
  pin_code: Yup.number().required({en:"Pin Code is Required", ar: "مطلوب رمز التعريف الشخصي"}),
  about_business: Yup.string().nullable(true),
});

//bussiness details validator
export const linkDetailValidator = Yup.object().shape({
  company_link: Yup.string().nullable(true),
  facebook_link: Yup.string().nullable(true),
  twitter_link: Yup.string().nullable(true),
  linked_in_link: Yup.string().nullable(true),
  skype_link: Yup.string().nullable(true),
});

//UserDetails Validator

export const UserDetailValidator = Yup.object().shape({
  name: Yup.string().required({en:"Full Name is Required",ar:"الإسم الكامل ضروري"}),
  mobile: Yup.number().required({en:"Mobile No Required",ar:"الجوال غير مطلوب"}),
  pin_code: Yup.number().required({en:"Pin code Is Required",ar:"الرقم السري مطلوب"}),
  address: Yup.string().nullable(true),
});

/* Review Validator */
export const ReviewDetailValidator = Yup.object().shape({
  user_name: Yup.string().required({en:"Enter Your Name",ar:"أدخل أسمك"}),
  review_comment: Yup.string().required({en:"Write a Comment",ar:"أكتب تعليقا"}),
  user_mail: Yup.string()
    .email({en:"Must valid Email",ar:"يجب أن يكون البريد الإلكتروني صالحًا"})
    .required({en:"Mail Id Required", ar:"معرف البريد مطلوب"}),
});

/* Menu Validator */
export const MenuDetailValidator = Yup.object().shape({
  main_item: Yup.string().required("Selct Main Item"),
  food_name: Yup.string().required("Write Food Name"),
  price: Yup.number().required("Enter Price"),
  food_type: Yup.string().required("Select Food Type"),
});

/* Enquiry Validator */

export const EnquiryValidator = Yup.object().shape({
  name: Yup.string().required({en:"Enter Your Name",ar:"أدخل أسمك"}),
  phone: Yup.number().required({en:"Enter Your Mobile No", ar:"أدخل رقم هاتفك المحمول"}),
  enquiry: Yup.string().required({en:"Write Your Message",ar:"اكتب رسالتك"}),
});

/* Amenties Validator */
export const AmentiesValidator = Yup.object().shape({
  amenities_name: Yup.string().required("Enter Amenties Name"),
});

/** Business Offer Validator */
export const businessOfferValidator = Yup.object().shape({
  offer: Yup.string().required({en:"Offer Name is Required",ar:"اسم العرض مطلوب"}),
  offer_desc: Yup.string().required({en:"Offer Description is Required", ar: "وصف العرض مطلوب"}),
});
export const businessDealValidator = Yup.object().shape({
  deal: Yup.string().required({en:"Deal Name is Required", ar: "اسم الصفقة مطلوب"}),
  deal_desc: Yup.string().required({en:"Deal Description is Required", ar: "وصف الصفقة مطلوب"}),
});


export const userDataValidator = Yup.object().shape({
  name: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  mobile: Yup.number().required('Mobile number is required'),
  nationalId: Yup.string().required('National ID is required'),
  city: Yup.string().required('City is required'),
  address: Yup.string().required('Address is required'),
  pin_code: Yup.number().required('Pincode is required'),
});
