/** External Imports */
import "bootstrap/dist/css/bootstrap.min.css";

import { Col, Container, Row, Form, Input, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

/** Internal Imports */

import { linksRequest, linkGetRequest } from "../../store/bussiness/bussiness.action";
import { linkDetailValidator } from "../../validators/Validators";
import { bussnessCatSelector } from "../../store/bussiness/bussiness.model";
import { useTranslation } from "react-i18next";
import "../../assets/css/main-pages.css";
import { bUserSelector } from "../../store/businessuser/businessUser.action";

function LinkDetail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { id } = useParams();
  const arabicStatus = i18n.language == "ar";
  /* Selectores */
  const { getBussinessDetails } = useSelector(bussnessCatSelector);
  const { businessId } = useSelector(bUserSelector);
  /** States */
  const [langSwitch, setLangSwitch] = useState(1);

  const initialValues = {
    company_link: getBussinessDetails?.link ?  getBussinessDetails?.link?.company_link :"",
    facebook_link: getBussinessDetails?.link ?getBussinessDetails?.link?.facebook_link:   "",
    twitter_link: getBussinessDetails?.link ?getBussinessDetails?.link?.twitter_link :"",
    linked_in_link: getBussinessDetails?.link ?getBussinessDetails?.link?.linked_in_link :"",
    skype_link: getBussinessDetails?.link  ?getBussinessDetails?.link?.skype_link :"",
  };
  const onSubmit = (formData) => {
    console.log(formData, "formdatass");
    delete formData.linked_in
    formData.business_id = parseInt(id);
    formData.language_type = langSwitch;
    formData.arabicStatus = arabicStatus
    console.log(formData,34578)
    dispatch(linksRequest(formData));
  };


  /* UseEffect */
  /* UseEffect */
  useEffect(() => {
    console.log(id,"idfkhdgfdh")
    dispatch(linkGetRequest(id));
  }, [id])
  /** Form Data */
  const formik = useFormik({
    initialValues,
    enableReinitialize:true,
    validationSchema: linkDetailValidator,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Row className="justify-content-center mt-5 mx-2">
          <Col lg={12}>
            <div className="add-listing-form details-listing-form form-space mb-60 wow fadeInUp">
              <Row className="mb-2">
                <Col md={6}>
                  {" "}
                  <h4 className="title">{t("social_media") + " " + t("information")}</h4>
                </Col>
                <Col md={6}>
                  <div className={`${arabicStatus ? "text-start" : "text-end"}`}>
                    {langSwitch == 1 ? (
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => setLangSwitch(2)}
                      >
                        اﻟﻌﺮﺑﻴﺔ
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => setLangSwitch(1)}
                      >
                        English
                      </button>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4} lg={4}>
                  <Form.Group as={Col} lg={6} className="food-menu mx-1">
                    <Form.Label className="label-style">
                      {t("company") + " " +t("link")} :
                    </Form.Label>
                    <Form.Control type="text"
                      className="form_control"
                      placeholder={t("website")}
                      name="company_link"
                      value={values.company_link}
                      dir={langSwitch == 1 ? "" : "rtl"} 
                      onChange={handleChange} />
                    {touched?.company_link && errors.company_link?.en &&
                      <p className="text-danger">{arabicStatus ? errors.company_link.ar : errors.company_link.en}</p>
                    }
                  </Form.Group>
                </Col>
                <Col  md={4} lg={4}>
                  <Form.Group as={Col} lg={6} className="food-menu mx-1">
                    <Form.Label className="label-style">
                      {t("facebook") + " " +t("link")} :
                    </Form.Label>
                    <Form.Control type="text"                      
                      placeholder={t("facebook") + " " +t("link")} 
                      name="facebook_link"
                      value={values.facebook_link}
                      dir={langSwitch == 1 ? "" : "rtl"} 
                      onChange={handleChange} />
                    {touched?.facebook_link && errors.facebook_link?.en &&
                      <p className="text-danger">{arabicStatus ? errors.facebook_link.ar : errors.company_link.en}</p>
                    }
                  </Form.Group>
                </Col>

                <Col md={4} lg={4}>
                <Form.Group as={Col} lg={6} className="food-menu mt-1">
                    <Form.Label className="label-style">
                      {t("twitter") + " " +t("link")} :
                    </Form.Label>
                    <Form.Control 
                    type="text"
                    className="form_control"
                    placeholder={t("twitter") + " " + t("optional")}
                    name="twitter_link"
                    value={values.twitter_link}
                    onChange={handleChange}     
                    dir={langSwitch == 1 ? "" : "rtl"}                
                    />
                    {errors.twitter_link?.en &&
                      <p className="text-danger">{arabicStatus ? errors.twitter_link.ar : errors.twitter_link.en}</p>
                    }
                  </Form.Group>
                </Col>
              </Row>
              <Row>

                <Col md={4} lg={4}>
                <Form.Group as={Col} lg={6} className="food-menu mx-1">
                    <Form.Label className="label-style">
                    {t("linked_in")} :
                    </Form.Label>
                    <Form.Control 
                    type="text"
                    className="form_control"
                    placeholder={t("linked_in") + " " + t("optional")}
                    name="linked_in"
                    value={values.linked_in}
                    onChange={handleChange}     
                    dir={langSwitch == 1 ? "" : "rtl"} 
                    />
                    {errors.linked_in?.en &&
                      <p className="text-danger">{arabicStatus ? errors.linked_in.ar : errors.linked_in.en}</p>
                    }
                  </Form.Group>
                </Col> 
                <Col md={4} lg={4}>
                <Form.Group as={Col} lg={6} className="food-menu mx-1">
                    <Form.Label className="label-style">
                    {t("skype")+ " " + t("link")} :
                    </Form.Label>
                    <Form.Control 
                    type="text"
                    className="form_control"
                    placeholder={t("skype") + " " + t("optional")}
                    name="skype_link"
                    value={values.skype_link}
                    onChange={handleChange}      
                    dir={langSwitch == 1 ? "" : "rtl"} 
                    />
                    {errors.skype_link?.en &&
                      <p className="text-danger">{arabicStatus ? errors.skype_link.ar : errors.skype_link.en}</p>
                    }
                  </Form.Group>
                </Col>                                
                </Row>
              
                <Button variant="success" className="continue mx-2 mb-2 float-end" type="submit">
                {t("save") + " & " + t("continue")}
              </Button>     
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default LinkDetail;
