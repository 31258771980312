import { call, delay, put, takeEvery } from "redux-saga/effects";
import { decryptdata, encryptdata } from "../../utils/encrypt&decrypt";
import Swal from "sweetalert2";
import { secretKey } from "../../services/config";
import {
  GroupActionTypes,
  LocationActionTypes,
  PlaceActionTypes,
  CountryActionTypes,
  subLocationActionTypes,
} from "./settings.model";
import {
  addgroup,
  authorizeSocial,
  getProfile,
  updateProfile,
  getSelectedGroup,
  getSocial,
  getfbpagedetails,
  getusergroup,
  updateCurrent,
  updategroup,
  syncPosts,
  getBYLocation,
  getByCountry,
  getByPlace,
  getSubLocation,
} from "./settings.api";
import {
  addGroupFailure,
  addGroupSuccess,
  getProfileSuccess,
  getProfileFailure,
  authSocialsFailed,
  authSocialsSuccess,
  getcurrentGrpFailed,
  getcurrentGrpSuccess,
  getfbpagedetailsFailed,
  getfbpagedetailsSuccess,
  getsocialFailure,
  getsocialSuccess,
  getusergroupFailure,
  getusergroupSuccess,
  setCrntgrpSuccess,
  usergroupUpdateFailure,
  usergroupUpdateSucess,
  postProfileSuccess,
  postProfileFailure,
  setColor,
  setThemeValue,
  syncpostSuccess,
  syncpostFailure,
  settingLocationSuccess,
  settingLocationFailure,
  settingCountryFailure,
  settingCountrySuccess,
  settingPlaceSuccess,
  settingPlaceFailure,
  subLocationSuccess,
  subLocationFailure
} from "./settings.action";


export function* createGroup(action) {
  try {
    const response = yield call(addgroup, action.payload);
    yield put(addGroupSuccess(response));
  } catch (error) {
    yield put(addGroupFailure(error));
  }
}

export function* getProfileDetails(action) {
  try {
    const response = yield call(getProfile, action.payload);
    console.log(response, "ProfileDataaa");
    yield put(getProfileSuccess(response));
  } catch (error) {
    yield put(getProfileFailure(error));
  }
}

export function* PostProfileDetails(action) {
  try {
    const response = yield call(updateProfile, action.payload);
    yield put(postProfileSuccess(response));
  } catch (error) {
    yield put(postProfileFailure(error));
  }
}

export function* getUserGroup(action) {
  try {
    const response = yield call(getusergroup, action.payload);
    yield put(getusergroupSuccess(response));
  } catch (error) {
    yield put(getusergroupFailure(error));
  }
}

export function* getSocials(action) {
  try {
    const response = yield call(getSocial, action.payload);
    yield put(getsocialSuccess(response));
  } catch (error) {
    yield put(getsocialFailure(error));
  }
}

export function* updateUserGroup(action) {
  try {
    const response = yield call(updategroup, action.payload);
    yield put(usergroupUpdateSucess(response));
  } catch (error) {
    yield put(usergroupUpdateFailure(error));
  }
}

export function* GetFBPages(action) {
  try {
    const response = yield call(getfbpagedetails, action.payload);
    yield put(getfbpagedetailsSuccess(response));
  } catch (error) {
    yield put(getfbpagedetailsFailed(error));
  }
}

export function* handleSettingsChangeColor({ payload }) {
  yield put(setColor(payload));
  yield call(delay, 10);
  yield put(setThemeValue());
}

export function* GetCurrentGroup(action) {
  try {
    const response = yield call(getSelectedGroup, action.payload);
    console.log(response, "curent respo");
    yield put(getcurrentGrpSuccess(response));
  } catch (error) {
    yield put(getcurrentGrpFailed(error));
  }
}

export function* AuthSocials(action) {
  try {
    const response = yield call(authorizeSocial, action.payload);
    yield put(authSocialsSuccess(response));
  } catch (error) {
    yield put(authSocialsFailed(error));
  }
}

export function* UpdateCurrentGrp(action) {
  try {
    console.log(action, "kadiufhi");
    const response = yield call(updateCurrent, action.payload);
    console.log("first groupMessage", response);
    yield put(setCrntgrpSuccess(response));
    // yield put(GroupActionTypes.SET_CRNT_GRP_SUCESS, response);
  } catch (error) {
    // yield put(GroupActionTypes.SET_CRNT_GRP_FAILURE,error);
  }
}

export function* SyncPosts(action) {
  try {
    const response = yield call(syncPosts, action.payload);
    yield put(syncpostSuccess(response));
  } catch (error) {
    yield put(syncpostFailure(error));
  }
}

// export const settingsChangeColor = (color) => async (dispatch) => {
//   dispatch(setColor(color));
//   await wait(10);
//   dispatch(setThemeValues());
// };

export function* handleFetchLocation(action) {
  try {
    const response = yield call(getBYLocation);
    yield put(settingLocationSuccess(response));
    console.log(response, "settingLocation");
  } catch (e) {
    yield put(settingLocationFailure(e));
  }
}

export function* handleFetchByCountry(action) {
  try {
    const response = yield call(getByCountry);
    yield put(settingCountrySuccess(response));
    console.log(response, "settingCountry");
  } catch (e) {
    yield put(settingCountryFailure(e));
  }
}

export function* handleFetchByPlace(action) {
  try {
    const response = yield call(getByPlace);
    yield put(settingPlaceSuccess(response));
    console.log(response, "settingPlace");
  } catch (e) {
    yield put(settingPlaceFailure(e));
  }
}
export function* handleSubLocation(action) {
  try {
    const response = yield call(getSubLocation);
    yield put(subLocationSuccess(response));
    console.log(response, "settingLocation");
  } catch (e) {
    yield put(subLocationFailure(e));
  }
}

export function* SettingSaga() {
  yield takeEvery(GroupActionTypes.ADD_GROUP_REQUEST, createGroup);
  yield takeEvery(
    GroupActionTypes.GET_PROFILE_SETTING_REQUEST,
    getProfileDetails
  );

  yield takeEvery(
    GroupActionTypes.POST_PROFILE_SETTING_REQUEST,
    PostProfileDetails
  );

  yield takeEvery(GroupActionTypes.GET_USERGROUP_REQUEST, getUserGroup);
  yield takeEvery(GroupActionTypes.GET_SOCIAL_REQUEST, getSocials);
  yield takeEvery(GroupActionTypes.UPDATE_USERGROUP_REQUEST, updateUserGroup);
  yield takeEvery(GroupActionTypes.GET_FB_PAGE_REQUEST, GetFBPages);
  yield takeEvery(GroupActionTypes.GET_CURRENT_GRP_REQUEST, GetCurrentGroup);
  yield takeEvery(GroupActionTypes.AUTH_SOCIALS_REQUEST, AuthSocials);
  yield takeEvery(GroupActionTypes.SET_CRNT_GRP_REQUEST, UpdateCurrentGrp);
  yield takeEvery(GroupActionTypes.CHANGE_COLOR, handleSettingsChangeColor);
  yield takeEvery(GroupActionTypes.POST_SYNC_REQUEST, SyncPosts);
  yield takeEvery(
    LocationActionTypes.LOCATION_LIST_REQUEST,
    handleFetchLocation
  );
  yield takeEvery(
    CountryActionTypes.COUNTRY_LIST_REQUEST,
    handleFetchByCountry
  );
  yield takeEvery(PlaceActionTypes.PLACE_LIST_REQUEST, handleFetchByPlace);
  yield takeEvery(
    subLocationActionTypes.SUB_LOCATION_LIST_REQUEST,
    handleSubLocation
  );
}
