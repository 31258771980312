// import { NavLink } from "react-router-dom";
// import { Button, Col, Row } from "react-bootstrap";
// import { useFormik } from "formik";
// import { useDispatch, useSelector } from "react-redux";
// import { authSelector, signUpUserRequest } from "../store/auth/auth.action";
// import { useEffect } from "react";
// import Swal from "sweetalert2";
// import { excludedMessages } from "../utils/Constants";
// import { clearAuthMessage } from "../store/auth/auth.action";

// const Signup = () => {
//   //useStates
//   const dispatch = useDispatch();

//   const formik = useFormik({
//     initialValues: {
//       mail_id: "",
//       password: "",
//     },
//     onSubmit: (values) => {
//       console.log(values);
//       dispatch(signUpUserRequest(values));
//     },
//     validate: (values) => {
//       const errors = {};
//       if (!values.mail_id) {
//         errors.mail_id = "Required";
//       }
//       if (!values.password) {
//         errors.password = "Required";
//       }
//       return errors;
//     },
//   });

//   const { handleChange, handleSubmit, values, errors, touched } = formik;

//   //useSelector
//   const { groupMessage, groupError, isLoading } = useSelector(authSelector);

//   //useEffect
//   useEffect(() => {
//     console.log(groupMessage, excludedMessages, "signup-console");
//     if (!excludedMessages.includes(groupMessage))
//       Swal.fire({
//         title: groupMessage,
//         icon: "success",
//       }).then((data) => {
//         dispatch(clearAuthMessage());
//       });
//   }, [groupMessage]);

//   useEffect(() => {
//     console.log(groupError, excludedMessages, "signup-console");
//     if (!excludedMessages.includes(groupError))
//       Swal.fire({
//         title: groupError,
//         icon: "warning",
//       }).then((data) => {
//         dispatch(clearAuthMessage());
//       });
//   }, [groupError]);

//   useEffect(() => {
//     document.body.classList.add("h-100");
//     const root = document.getElementById("root");
//     if (root) {
//       root.classList.add("h-100");
//     }
//     return () => {
//       document.body.classList.remove("h-100");
//       if (root) {
//         root.classList.remove("h-100");
//       }
//     };
//   }, []);

//   return (
//     <>
//       <div className="fixed-background" />
//       <div className="container-fluid p-0 h-100 position-relative login_pages">
//         <div className="row g-0 h-100">
//           <div className="offset-0 col-12 d-none d-lg-flex offset-md-1 col-lg h-lg-100">
//             <div className="min-h-100 d-flex align-items-center">
//               <div className="w-100 w-lg-50 w-xxl-50">
//                 <div>
//                   <div className="mb-5">
//                     {/* <div class="logo "><div class="img" style={{width:"100%",height:"90px"}}></div></div> */}
//                     <div
//                       className="logo-default h-100 mb-5"
//                       style={{ width: "100%", minHeight: "90px" }}
//                     />
//                     <h1 className="display-3 text-white">
//                       Create a Workplace Culture that Inspires and Innovates.
//                     </h1>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
//             <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
//               <div className="sw-lg-100 px-5">
//                 <div className="mb-5">
//                   <h2 className="cta-1 mb-0 text-primary">
//                     Welcome to Culture Catalyst,
//                   </h2>
//                   <h2 className="cta-1 text-primary">let's get started!</h2>
//                 </div>
//                 <div className="mb-5">
//                   <p className="h6">
//                     Already have an account?{" "}
//                     <NavLink to="/" className="login_txt">
//                       Signin
//                     </NavLink>
//                     .
//                   </p>
//                 </div>
//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3 filled form-group tooltip-end-top">
//                     <input
//                       type="text"
//                       name="mail_id"
//                       id="mail_id"
//                       placeholder="Mail id"
//                       value={values.mail_id}
//                       onChange={handleChange}
//                       className={`form-control ${
//                         errors.mail_id && touched.mail_id ? "is-invalid" : ""
//                       }`}
//                     />
//                     {errors.mail_id && touched.mail_id && (
//                       <div>
//                         <p className="error text-danger">{errors.mail_id}</p>
//                       </div>
//                     )}
//                   </div>
//                   <div className="mb-3 filled form-group tooltip-end-top">
//                     <input
//                       type="password"
//                       name="password"
//                       id="password"
//                       placeholder="Create Password"
//                       value={values.password}
//                       onChange={handleChange}
//                       className={`form-control ${
//                         errors.password && touched.password ? "is-invalid" : ""
//                       }`}
//                     />
//                     {errors.password && touched.password && (
//                       <div>
//                         <p className="error text-danger">{errors.password}</p>
//                       </div>
//                     )}
//                   </div>
//                   <div className="text-end">
//                     <Button type="submit" loading={isLoading}>
//                       Register
//                     </Button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Signup;
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, signUpUserRequest, clearAuthMessage } from "../store/auth/auth.action";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { excludedMessages } from "../utils/Constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { signupValidator } from "../validators/Validators";

const Signup = () => {
  const dispatch = useDispatch();
  
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(signupValidator)
  });
  
  const onSubmit = (formData) => {
    delete formData.mail_id
    console.log(formData);
    formData.role_id="4"
    dispatch(signUpUserRequest(formData));
  };

  const { groupMessage, groupError, isLoading } = useSelector(authSelector);

  useEffect(() => {
    console.log(groupMessage, excludedMessages, "signup-console");
    if (!excludedMessages.includes(groupMessage))
      Swal.fire({
        title: groupMessage,
        icon: "success",
      }).then(() => {
        dispatch(clearAuthMessage());
      });
  }, [groupMessage]);

  useEffect(() => {
    console.log(groupError, excludedMessages, "signup-console");
    if (!excludedMessages.includes(groupError))
      Swal.fire({
        title: groupError,
        icon: "warning",
      }).then(() => {
        dispatch(clearAuthMessage());
      });
  }, [groupError]);

  useEffect(() => {
    document.body.classList.add("h-100");
    const root = document.getElementById("root");
    if (root) {
      root.classList.add("h-100");
    }
    return () => {
      document.body.classList.remove("h-100");
      if (root) {
        root.classList.remove("h-100");
      }
    };
  }, []);

  return (
    <>
      <div className="fixed-background" />
      <div className="container-fluid p-0 h-100 position-relative login_pages">
        <div className="row g-0 h-100">
          <div className="offset-0 col-12 d-none d-lg-flex offset-md-1 col-lg h-lg-100">
            <div className="min-h-100 d-flex align-items-center">
              <div className="w-100 w-lg-50 w-xxl-50">
                <div>
                  <div className="mb-5">
                    <div
                      className="logo-default h-100 mb-5"
                      style={{ width: "100%", minHeight: "90px" }}
                    />
                    <h1 className="display-3 text-white">
                      Create a Workplace Culture that Inspires and Innovates.
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
            <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
              <div className="sw-lg-100 px-5">
                <div className="mb-5">
                  <h2 className="cta-1 mb-0 text-primary">
                    Welcome to Culture Catalyst,
                  </h2>
                  <h2 className="cta-1 text-primary">let's get started!</h2>
                </div>
                <div className="mb-5">
                  <p className="h6">
                    Already have an account?{" "}
                    <NavLink to="/" className="login_txt">
                      Signin
                    </NavLink>
                    .
                  </p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3 filled form-group tooltip-end-top">
                    <input
                      type="text"
                      placeholder="Name"
                      {...register("name")}
                      className={`form-control ${errors.name ? "is-invalid" : ""}`}
                    />
                    {errors.mail_id && (
                      <div>
                        <p className="error text-danger">{errors.name.message}</p>
                      </div>
                    )}
                  </div>
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <input
                      type="text"
                      placeholder="Mail id"
                      {...register("email")}
                      className={`form-control ${errors.email ? "is-invalid" : ""}`}
                    />
                    {errors.mail_id && (
                      <div>
                        <p className="error text-danger">{errors.email.message}</p>
                      </div>
                    )}
                  </div>
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <input
                      type="password"
                      placeholder="Create Password"
                      {...register("password")}
                      className={`form-control ${errors.password ? "is-invalid" : ""}`}
                    />
                    {errors.password && (
                      <div>
                        <p className="error text-danger">{errors.password.message}</p>
                      </div>
                    )}
                  </div>
                  <div className="text-end">
                    <Button type="submit" disabled={isSubmitting || isLoading}>
                      Register
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
