import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { LineChart } from "@mui/x-charts/LineChart";
import { useDispatch, useSelector } from "react-redux";
import {
  adminSelector,
  cardStatRequest,
  dashStatRequest,
} from "../../store/admin/admin.action";
import { Skeleton } from "@mui/material";
import _ from "lodash";
import { BarChart } from "@mui/x-charts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faList, faUsers } from "@fortawesome/free-solid-svg-icons";

const DashBoard = () => {
  const dispatch = useDispatch();
  const { cardLoad, cardStat, dashStats } = useSelector(adminSelector);

  useEffect(() => {
    dispatch(cardStatRequest());
    dispatch(dashStatRequest());
  }, []);

  console.log(dashStats, "dashStatsjsx");

  const chartSetting = {
    yAxis: [
      {
        label: "Business",
      },
    ],
    width: 500,
    height: 400,
  };

  const valueFormatter = (value) => `${value}mm`;

  return (
    <div>
      <div>
        <h2>DashBoard</h2>
      </div>
      <Card className="mt-5">
        <div className="row g-5 py-5 px-5 row-cols-1 row-cols-lg-3">
          <div className="col d-flex align-items-start">
            {cardLoad ? (
              <>
                <Skeleton  animation={"pulse"}
                  className="rounded-circle text-body-emphasis iconscls h-100"
                  variant="circular"
                />
                <div className="paradiv w-50 align-items-center">
                  <Skeleton  animation={"pulse"} className="paras w-50" />
                  <Skeleton  animation={"pulse"} className="paras1" />
                  <Skeleton  animation={"pulse"} className="paras1" />
                </div>
              </>
            ) : (
              <>
                <div className="rounded-circle text-body-emphasis iconscls">
                <FontAwesomeIcon icon={faBriefcase}  className="fs-2"/>
                </div>
                <div className="paradiv">
                  <p className="paras"> Total Businesses</p>
                  <p className="paras1">
                    {cardStat?.business_user ?? 0} + Businesses
                  </p>
                </div>
              </>
            )}
          </div>
          <div className="vi"></div>
          <div className="col d-flex align-items-start">
            {cardLoad ? (
              <>
                <Skeleton  animation={"pulse"}
                  className="rounded-circle text-body-emphasis iconscls h-100"
                  variant="circular"
                />
                <div className="paradiv w-50 align-items-center">
                  <Skeleton  animation={"pulse"} className="paras w-50" />
                  <Skeleton  animation={"pulse"} className="paras1" />
                  <Skeleton  animation={"pulse"} className="paras1" />
                </div>
              </>
            ) : (
              <>
                <div className="rounded-circle text-body-emphasis iconscls">
                <FontAwesomeIcon icon={faUsers}  className="fs-2"/>
                </div>
                <div className="paradiv">
                  <p className="paras">Total Users</p>
                  <p className="paras1">{cardStat?.user ?? 0} + Users</p>
                </div>
              </>
            )}
            <div className="vi2"></div>
          </div>
          <div className="col d-flex align-items-start">
            {cardLoad ? (
              <>
                <Skeleton  animation={"pulse"}
                  className="rounded-circle text-body-emphasis iconscls h-100"
                  variant="circular"
                />
                <div className="paradiv w-50 align-items-center">
                  <Skeleton  animation={"pulse"} className="paras w-50"/>
                  <Skeleton  animation={"pulse"} className="paras1" />
                  <Skeleton  animation={"pulse"} className="paras1" />
                </div>
              </>
            ) : (
              <>
                <div className="rounded-circle text-body-emphasis iconscls">
                <FontAwesomeIcon icon={faList} className="fs-2"/>
                </div>
                <div className="paradiv">
                  <p className="paras">Total Categories</p>
                  <p className="paras1">
                    {cardStat?.category ?? 0} + Categories
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </Card>
      <div className="mt-5 mx-2">
        <h1 className="text-muted fw-bold">No. of business Users (category wise)</h1>
        {dashStats?.data?.length > 0 ? (
          <BarChart
            dataset={dashStats?.data}
            xAxis={[{ scaleType: "band", dataKey: "categoryName" }]}
            series={[
              {
                dataKey: "businessCount",
                label: "Count",
                valueFormatter: (value) => {
                  console.log(value, "kbdfhvbh");
                },
              },
            ]}
            layout="vertical"
            {...chartSetting}
          />
        ) : (
          <>
          <h2>No data found :(</h2></>
        )}
      </div>
    </div>
  );
};

export default DashBoard;
