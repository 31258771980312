/* External Imports */
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
/* Internal Imports */
import { CategoryAddListValiator } from "./Validator";
import { deleteCatRequest, getCatRequest, postCatRequest, updateCatRequest } from "./Action";

import { CatAddSelector } from "./Selector";
import { soonCategory, popularCategory } from "../../utils/Constants";
const CatergroryPage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let arabicStatus = i18n.language == "ar"
  /* Selectors */
  const { getCatData, sendSuccess, catUpdatedSuccess, catDeleteSuccess } = useSelector(CatAddSelector);
  console.log(getCatData, "65dcsf")

  /* States */
  const [id, setId] = useState(0);
  const [image, setImage] = useState();
  const [validated, setValidated] = useState(false);
  const [langSwitch, setLangSwitch] = useState(1);
  const [errorMsg, seterrorMsg] = useState({
    image: false,    
  });
  const [soonCategoryId, setsoonCategoryID] = useState();
  const [popularCategoryId, setpopularCategoryId] = useState();
  const fileInputRef = useRef(null);
  /* Function */
  const onSubmit = (formData) => {
    if (!image) {
      setValidated(false);
    } else {
      // formData.arabicStatus = arabicStatus
      formData.language_type = langSwitch
      formData.image = image;
      formData.soon_categories = soonCategoryId;
      formData.popular_categories = popularCategoryId;
      // console.log(formData,375389)
      if (id == 0) {
        dispatch(postCatRequest(formData));
      } else {
        dispatch(updateCatRequest({ formData, id }))
        setId(0)
      }
    }
  };
  const handleCategoryChange = (event,type) => {    
    type == "soon" ? setsoonCategoryID(event.target.value)  :  setpopularCategoryId(event.target.value);
  };  

  /** Form */
  const initialValues = {
    name: "",
    view_order: "",
    soon_categories: "",
    popular_categories: "",
    description: ""
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CategoryAddListValiator,
    onSubmit,
  });

  const { handleSubmit, handleChange, values, touched, errors, resetForm, setValues } =
    formik;

  const handleEdit = (cat) => {
    setId(cat.id)
    resetForm();
  }
  const handleDelete = (id) => {
    dispatch(deleteCatRequest(id))
    }
console.log(errors,345793)

  /* UseEffect */
  useEffect(() => {
    dispatch(getCatRequest({}));
  }, []);

  useEffect(() => {
    if (sendSuccess == true || catUpdatedSuccess == true || catDeleteSuccess == true) {
      resetForm();
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      setImage(null)
      dispatch(getCatRequest({}));
    }
  }, [sendSuccess, dispatch, catUpdatedSuccess, catDeleteSuccess]);

  useEffect(() => {
    if (arabicStatus)
      setLangSwitch(2)
    else
      setLangSwitch(1)
  }, [arabicStatus])

  return (
    <div>
      <Row className="h-100">
        <Col>
          <Col>
            <Row className="mb-2">
              <Col md={6}>
                {" "}
                <h2 className="text-muted">{t("category")} {id == 0 ? t("add") : t("update")} </h2>
              </Col>
              <Col md={6}>
                <div className={`${arabicStatus ? "text-start" : "text-end"} `}>
                  {langSwitch == 1 ? (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => setLangSwitch(2)}
                    >
                      اﻟﻌﺮﺑﻴﺔ
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => setLangSwitch(1)}
                    >
                      English
                    </button>
                  )}
                </div>
              </Col>
            </Row>
            <Card body className="mb-5 mt-2">

              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}>
                <Row className="mb-3">
                  <Form.Group as={Col} lg={6} className="food-menu">
                    <Form.Label className="label-style">
                      {t("category") + " " + t("name")}
                    </Form.Label>
                    <Form.Control type="text" name="name" value={values.name} onChange={handleChange} dir={langSwitch == 1 ? "" : "rtl"} placeholder={t("category") + " " + t("name")} />
                    {errors?.name && (
                      <p className="text-danger">{errors?.name}</p>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} lg={6}>
                    <Form.Label className="label-style">{t("view")+ " " + t("order")}</Form.Label>
                    <Form.Control className="border-business"
                      type="number"
                      name="view_order"
                      placeholder={t("view") + " " + t("order")}
                      value={values.view_order}
                      onChange={handleChange}
                      dir={langSwitch == 1 ? "" : "rtl"} />
                    {errors?.view_order && (
                      <p className="text-danger">
                        {errors?.view_order}
                      </p>
                    )}
                  </Form.Group>
                  </Row>
                  <Row>
                 {console.log(soonCategory,5646456)}
                  <Form.Group as={Col} lg={6}>
                    <Form.Label className="label-style">{t("soon")+ " " + t("category")}</Form.Label>                   
                      <Form.Control
                    className="dn-space border-business"
                    as="select"
                    onChange={(e) => {
                      handleCategoryChange(e,"soon");                      
                    }}
                    defaultValue={soonCategoryId}
                    placeholder={t("soon") + " " + t("category")}
                  >
                    <option value={0} key={0}>{t("select")}</option>
                    {soonCategory &&
                      soonCategory?.map((fields) => {
                        
                        return (
                          <option value={fields.id} key={fields.id}>
                            {fields.value}
                          </option>
                        );
                      })}
                  </Form.Control>
                  {errors?.soon_categories && (
                      <p className="text-danger">
                        {errors?.soon_categories}
                      </p>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} lg={6}>
                    <Form.Label className="label-style">{t("popular")+ " " + t("category")}</Form.Label>
                    <Form.Control
                    className="dn-space border-business"
                    as="select"
                    onChange={(e) => {
                      handleCategoryChange(e,"popular");                      
                    }}
                    defaultValue={popularCategoryId}
                    placeholder={t("popular") + " " + t("category")}
                  >
                    <option value={0} key={0}>{t("select")}</option>
                    {popularCategory &&
                      popularCategory?.map((fields) => {
                        return (
                          <option value={fields.id} key={fields.id}>
                            {fields.value}
                          </option>
                        );
                      })}
                  </Form.Control>
                  </Form.Group>
                  
                </Row>
                <Row>
                <Form.Group as={Col} lg={6}>
                    <Form.Label className="label-style">{t("description")}</Form.Label>
                    <Form.Control className="border-business"
                      as="textarea"
                      name="description"
                      placeholder={t("description")}
                      value={values.description}
                      onChange={handleChange}
                      dir={langSwitch == 1 ? "" : "rtl"} />
                    {errors?.description && (
                      <p className="text-danger">
                        {errors?.description}
                      </p>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} lg={6} className="food-menu tooltip-end-top filled">
                    <Form.Label className="label-style"> {t("category") + " " + t("logo")} : </Form.Label>
                    <Form.Control
                      className="border-business"
                      type="file"
                      accept="image/*"
                      name="image"
                      onChange={(e) => setImage(e.target.files[0])}
                      placeholderText=""
                      ref={fileInputRef}
                    />
                     {!image && errorMsg?.image && (
                    <p className="text-danger">{t("image") + " " + t("is") + " " + t("required")}</p>
                  )}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    lg={12}
                    className="text-end"
                  >
                    <Button variant="success" type="submit" onClick={() => {
                if (!image) {
                  seterrorMsg({
                    image: !image,                    
                  });
                  setValidated(false);
                }
              }}>{id == 0 ? "Submit" : "Update"}</Button>
                  </Form.Group>
                </Row>
              </Form>
            </Card>
          </Col>
          <Col>
            <h2 >List</h2>

            <Card body className="mb-5 mt-2 d-flex">

              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>{t("s_no")}</th>
                    <th>{t("catergory") + " " + t("name")}</th>
                    <th>{t("description")}</th>
                    <th>{t("action")}</th>
                  </tr>
                </thead>

                <tbody>
                  {getCatData &&
                    getCatData?.map((ele, ind) => {
                      return (
                        <tr key={ele.id}>
                          <td>{ind + 1}</td>
                          <td>{ele.name}</td>
                          <td>{ele.description}</td>
                          <td>
                            {" "}
                            <td>
                              <Button
                                variant="info"
                                onClick={() => handleEdit(ele)}
                              >
                                {t("edit")}
                              </Button>{" "}
                              <Button
                                variant="danger"
                                // onClick={() => handleDelete(ele.id)}
                              >
                                {t("delete")}
                              </Button>
                            </td>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default CatergroryPage;
