import { action } from "typesafe-actions";
import { categoryAddActionType } from "./Model";

export const postCatRequest = (req) =>
  action(categoryAddActionType.POSTCATEGORY_REQUEST, req);
export const postCatSuccess = (res) =>
  action(categoryAddActionType.POSTCATEGORY_SUCCESS, res);
export const postCatFailure = (err) =>
  action(categoryAddActionType.POSTCATEGORY_FAILURE, err);


export const updateCatRequest = (req) =>
  action(categoryAddActionType.UPDATECATEGORY_REQUEST, req);
export const updateCatSuccess = (res) =>
  action(categoryAddActionType.UPDATECATEGORY_SUCCESS, res);
export const updateCatFailure = (err) =>
  action(categoryAddActionType.UPDATECATEGORY_FAILURE, err);


export const deleteCatRequest = (req) =>
  action(categoryAddActionType.DELETECATEGORY_REQUEST, req);
export const deleteCatSuccess = (res) =>
  action(categoryAddActionType.DELETECATEGORY_SUCCESS, res);
export const deleteCatFailure = (err) =>
  action(categoryAddActionType.DELETECATEGORY_FAILURE, err);

export const getCatRequest = (req) =>
  action(categoryAddActionType.GETCATEGORY_REQUEST, req);
export const getCatSuccess = (res) =>
  action(categoryAddActionType.GETCATEGORY_SUCCESS, res);
export const getCatFailure = (err) =>
  action(categoryAddActionType.GETCATEGORY_FAILURE, err);



export const postProductRequest = (req) =>
  action(categoryAddActionType.POSTPRODUCT_REQUEST, req);
export const postProductSuccess = (res) =>
  action(categoryAddActionType.POSTPRODUCT_SUCCESS, res);
export const postProductFailure = (err) =>
  action(categoryAddActionType.POSTPRODUCT_FAILURE, err);

export const getProductRequest = (req) =>
  action(categoryAddActionType.GETPRODUCT_REQUEST, req);
export const getProductSuccess = (res) =>
  action(categoryAddActionType.GETPRODUCT_SUCCESS, res);
export const getProductFailure = (err) =>
  action(categoryAddActionType.GETPRODUCT_FAILURE, err);

export const UpdateProductRequest = (req) =>
  action(categoryAddActionType.UPDATEPRODUCT_REQUEST, req);
export const UpdateProductSuccess = (res) =>
  action(categoryAddActionType.UPDATEPRODUCT_SUCCESS, res);
export const UpdateProductFailure = (err) =>
  action(categoryAddActionType.UPDATEPRODUCT_FAILURE, err);

export const deleteProductRequest = (req) =>
  action(categoryAddActionType.DELETEPRODUCT_REQUEST, req);
export const deleteProductSuccess = (res) =>
  action(categoryAddActionType.DELETEPRODUCT_SUCCESS, res);
export const deleteProductFailure = (err) =>
  action(categoryAddActionType.DELETEPRODUCT_FAILURE, err);


export const getUserDataRequest = (req) =>
  action(categoryAddActionType.GETUSERDATA_REQUEST, req);
export const getUserDataSuccess = (res) =>
  action(categoryAddActionType.GETUSERDATA_SUCCESS, res);
export const getUserDataFailure = (err) =>
  action(categoryAddActionType.GETUSERDATA_FAILURE, err);

export const getUserOrderDataRequest = (req) =>
  action(categoryAddActionType.GETUSERORDER_REQUEST, req);
export const getUserOrderDataSuccess = (res) =>
  action(categoryAddActionType.GETUSERORDER_SUCCESS, res);
export const getUserOrderDataFailure = (err) =>
  action(categoryAddActionType.GETUSERORDER_FAILURE, err);

export const orderDispatchRequest = (req) =>
  action(categoryAddActionType.ORDERDISPATCH_REQUEST, req);
export const orderDispatchSuccess = (res) =>
  action(categoryAddActionType.ORDERDISPATCH_SUCCESS, res);
export const orderDispatchFailure = (err) =>
  action(categoryAddActionType.ORDERDISPATCH_FAILURE, err);

