import { AdminActionTypes, adminInitialState } from "./admin.model";
import _ from "underscore";

const reducer = (state = adminInitialState, action) => {
  switch (action.type) {
    case AdminActionTypes.LOGIN_ADMIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case AdminActionTypes.LOGIN_ADMIN_SUCCESS:
      var { payload } = action;
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("authKey", payload?.data?.datas?.accessToken);
      localStorage.setItem("email", payload?.data?.datas?.email);

      return {
        ...state,
        isLoading: false,
        auth: payload?.data,
      };

    case AdminActionTypes.LOGIN_ADMIN_FAILURE:
      var { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload?.response?.statusText ?? "",
      };

    // userlist

    case AdminActionTypes.USERLIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case AdminActionTypes.USERLIST_SUCCESS:
      var { payload } = action;
      console.log(payload, "userlist payload suicess");
      return {
        ...state,
        isLoading: false,
        userData: payload?.data,
      };

    case AdminActionTypes.USERLIST_FAILURE:
      var { payload } = action;
      return {
        ...state,
        isLoading: false,
        error: payload?.response?.statusText ?? "",
      };

    // cardStat

    case AdminActionTypes.CARDSTAT_REQUEST:
      return {
        ...state,
        cardLoad: true,
      };

    case AdminActionTypes.CARDSTAT_SUCESS:
      var { payload } = action;
      console.log(payload, "cardStat payload suicess");
      return {
        ...state,
        cardLoad: false,
        cardStat: payload?.data?.data,
      };

    case AdminActionTypes.CARDSTAT_FAILURE:
      var { payload } = action;
      return {
        ...state,
        cardLoad: false,
        error: payload?.response?.statusText ?? "",
      };

    // cardStat

    case AdminActionTypes.DASHSTAT_REQUEST:
      return {
        ...state,
        cardLoad: true,
      };

    case AdminActionTypes.DASHSTAT_SUCESS:
      var { payload } = action;
      console.log(payload, "dashStat payload suicess");
      return {
        ...state,
        cardLoad: false,
        dashStats: payload?.data,
      };

    case AdminActionTypes.DASHSTAT_FAILURE:
      var { payload } = action;
      return {
        ...state,
        cardLoad: false,
        error: payload?.response?.statusText ?? "",
      };

    case AdminActionTypes.DEACTIVATE_REQUEST:
      return {
        ...state,
        deActLoad: true,
        deActSuccess: false,
      };
    case AdminActionTypes.DEACTIVATE_SUCCESS:
      return {
        ...state,
        deActLoad: false,
        deActSuccess: true,
      };
    case AdminActionTypes.DEACTIVATE_FAILURE:
      return {
        ...state,
        deActLoad: false,
        deActSuccess: false,
      };

    default:
      return state;
  }
};

export { reducer as AdminReducer };
