import { all, call, put, takeEvery } from "redux-saga/effects";
import * as AdminActions from "./admin.action";
import { AdminActionTypes } from "./admin.model";
import Swal from "sweetalert2";
import { deactivate, getCardStat, getDashStat, getUserList, signIn } from "./admin.api";

export function* adminLogin(action) {
  try {
    console.log(action.payload, 5456756);
    const response = yield call(signIn, action.payload);
    yield put(AdminActions.adminSigninSuccess(response));
    Swal.fire({
      title: "Admin Sign In",
      text: "Admin Sign In successfully",
      icon: "success",
    });
  } catch (e) {    
    Swal.fire({
      title: "Admin Sign In",
      text: e?.response?.data?.error,
      icon: "error",
    });
    yield put(AdminActions.adminSigninFailure(e));
  }
}

function* userListHandler(action) {
  try {
    const response = yield call(getUserList, action.payload);
    yield put(AdminActions.userListSuccess(response));
  } catch (error) {
    yield put(AdminActions.userListFailure(error));
  }
}

function* cardStatHandler(action) {
  try {
    const response = yield call(getCardStat, action.payload);
    yield put(AdminActions.cardStatSuccess(response));
  } catch (error) {
    yield put(AdminActions.cardStatFailure(error));
  }
}

function* dashStatHandler(action) {
  try {
    const resposne = yield call(getDashStat, action.payload);
    yield put(AdminActions.dashStatSuccess(resposne));
  } catch (error) {
    yield put(AdminActions.dashStatFailure(error));
  }
}

 function* handleDeactivate(action) {
  try {
    console.log(action.payload, 545655756);
    const response = yield call(deactivate, action.payload);
    yield put(AdminActions.deactivateSuccess(response));
    Swal.fire({
      title:action?.payload?.status==1 ? "Activated SuccessFully": "Deactivated successfully",
      text:action?.payload?.status==1 ? "Account Activated SuccessFully": "Account Deactivated successfully",
      icon: "success",
    });
  } catch (e) {    
    Swal.fire({
      title:action?.payload?.status==1 ? "Activated Failed": "Deactivated Failed",
      text:action?.payload?.status==1 ? "Account Activated Failed": "Account Deactivated Failed",
      icon: "error",
    });
    yield put(AdminActions.deactivateFailure(e));
  }
}

export function* AdminSaga() {
  yield takeEvery(AdminActionTypes.LOGIN_ADMIN_REQUEST, adminLogin);
  yield takeEvery(AdminActionTypes.USERLIST_REQUEST, userListHandler);
  yield takeEvery(AdminActionTypes.CARDSTAT_REQUEST, cardStatHandler);
  yield takeEvery(AdminActionTypes.DASHSTAT_REQUEST, dashStatHandler);
  yield takeEvery(AdminActionTypes.DEACTIVATE_REQUEST, handleDeactivate)
}
