/* External Import */
import React, { useState } from "react";
import { Col, Container, Row, Form, Input, Button } from "react-bootstrap";

import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

/* Internal Imports */
import { bussnessCatSelector } from "../../store/bussiness/bussiness.model";
import { timeSendRequest } from "../../store/bussiness/bussiness.action";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { bUserSelector } from "../../store/businessuser/businessUser.action";


/* Internal Imports */

function WorkingHours() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const name = localStorage.getItem("username");
  let arabicStatus = i18n.language == "ar";
  const { id } = useParams();

  /* Selectors */
  const { getBussinessDetails } = useSelector(bussnessCatSelector);
console.log(getBussinessDetails, "uik")
  const { businessId } = useSelector(bUserSelector);

  /*  State */
  const [startDate, setStartDate] = useState(new Date());


  const defaultTimings = {
    from: "00:00",
    to: "00:00"
  };

  // const getTimingsForDay = (day) => {
  //   const timings = getBussinessDetails?.timings?.[day];
  //   if (timings) {
  //     const parsedTimings = JSON.parse(timings);
  //     return {
  //       from: Object.values(parsedTimings)[0],
  //       to: Object.values(parsedTimings)[1],
  //     };
  //   }
  //   return defaultTimings;
  // };

  const [date, setDate] = useState({
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
  });

  console.log(date, "datassssss");


  /* Fucntion */

  const dataUpdated = (day, type, value) => {
    console.log(day, type, value, "123");
    setDate((prevDate) => ({
      ...prevDate,
      [day]: {
        ...prevDate[day],
        [type]: value,
      },
    }));
  };
  const handleCheckboxChange = (day) => {
    setDate((prevDate) => ({
      ...prevDate,
      [day]: {
        ...prevDate[day],
        from: !prevDate[day].from ? "holiday" : "",
        to: !prevDate[day].to ? "holiday" : "",
      },
    }));
  };



  const handleTimeSubmit = (date) => {
    // console.log(date, "ffff");
    // date.arabicStatus = arabicStatus
    // date.business_id = parseInt(id)
    
    const dataData = {
      ...date,         
      arabicStatus,     
      business_id: parseInt(id) 
    };
    dispatch(timeSendRequest(dataData));    
  };

  /* Form Data */
  const formik = useFormik({
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  /* UseEffect */

  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Row className="justify-content-center mt-5 mx-2">
          <Col lg={12}>
            <div class="add-listing-form form-space timing-listing-form mb-60 wow fadeInUp">
              <h4 class="title">{t("opening_hours")}</h4>
              <div class="row mx-2">
                {Object?.keys(date)?.map((day) => (
                  <div class="col-lg-12" key={day}>
                    <div class="timeing-list">
                      <Row>
                        <Col md={2} lg={2}>
                          <h5>{t(day)}</h5>
                        </Col>
                        <Col className="timing-section d-flex " md={4} lg={4}>
                          <p style={{ marginTop: 10 }} className="label-tab mx-1">
                            {t("from")}
                          </p>
                          <input
                            type="time"
                            value={date[day]?.from}
                            disabled={date[day]?.from === "holiday"}
                            onChange={(e) =>
                              dataUpdated(day, "from", e.target.value)
                            }
                          />
                          <p style={{ marginTop: 10 }} className="label-tab mx-1">
                            {t("to")}
                          </p>
                          <input
                            type="time"
                            value={date[day]?.to}
                            disabled={date[day]?.to === "holiday"}
                            onChange={(e) =>
                              dataUpdated(day, "to", e.target.value)
                            }
                          />
                        </Col>
                        <Col className="holiday" md={4} lg={4}>
                          <span className="label-holi">{t("holiday")}</span>
                          <Form.Check
                            className="cbox"
                            aria-label="option 1"
                            onChange={() => handleCheckboxChange(day)}
                            checked={date[day]?.to === "holiday" ? true : false}
                          />
                        </Col>

                        <hr className="mt-1 mb-2" />
                      </Row>
                    </div>
                  </div>
                ))}
              </div>
              <Button variant="success" className="continue mx-2 mb-2 float-end" type="submit" onClick={() => handleTimeSubmit(date)}>
                {t("submit") + " " + t("timing")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default WorkingHours;
