import { action } from "typesafe-actions";
import { GroupActionTypes,LocationActionTypes ,CountryActionTypes, PlaceActionTypes,subLocationActionTypes } from "./settings.model";

export const addGroupRequest = (req) =>
  action(GroupActionTypes.ADD_GROUP_REQUEST, req);

export const addGroupSuccess = (res) =>
  action(GroupActionTypes.ADD_GROUP_SUCCESS, res);

export const addGroupFailure = (err) =>
  action(GroupActionTypes.ADD_GROUP_FAILURE, err);

export const clearGroupMessages = (err) =>
  action(GroupActionTypes.CLEAR_MESSAGES, err);

export const getusergroupRequest = (err) =>
  action(GroupActionTypes.GET_USERGROUP_REQUEST, err);

export const getusergroupSuccess = (err) =>
  action(GroupActionTypes.GET_USERGROUP_SUCESS, err);

export const getusergroupFailure = (err) =>
  action(GroupActionTypes.GET_USERGROUP_FAILURE, err);

export const getsocialSuccess = (err) =>
  action(GroupActionTypes.GET_SOCIAL_SUCESS, err);

export const getsocialFailure = (err) =>
  action(GroupActionTypes.GET_SOCIAL_FAILURE, err);

export const getsocialRequest = (err) =>
  action(GroupActionTypes.GET_SOCIAL_REQUEST, err);

export const usergroupUpdateSucess = (props) =>
  action(GroupActionTypes.UPDATE_USERGROUP_SUCESS, props);

export const usergroupUpdateFailure = (props) =>
  action(GroupActionTypes.UPDATE_USERGROUP_FAILURE, props);

export const usergroupUpdateRequest = (props) =>
  action(GroupActionTypes.UPDATE_USERGROUP_REQUEST, props);

export const getfbpagedetailsRequest = (props) =>
  action(GroupActionTypes.GET_FB_PAGE_REQUEST, props);

export const getfbpagedetailsFailed = (props) =>
  action(GroupActionTypes.GET_FB_PAGE_FAILURE, props);

export const getfbpagedetailsSuccess = (props) =>
  action(GroupActionTypes.GET_FB_PAGE_SUCESS, props);

export const getcurrentGrpRequest = (props) =>
  action(GroupActionTypes.GET_CURRENT_GRP_REQUEST, props);

export const getcurrentGrpFailed = (props) =>
  action(GroupActionTypes.GET_CURRENT_GRP_FAILURE, props);

export const getcurrentGrpSuccess = (props) =>
  action(GroupActionTypes.GET_CURRENT_GRP_SUCESS, props);

export const authSocialsRequest = (props) =>
  action(GroupActionTypes.AUTH_SOCIALS_REQUEST, props);

export const authSocialsFailed = (props) =>
  action(GroupActionTypes.AUTH_SOCIALS_FAILURE, props);

export const authSocialsSuccess = (props) =>
  action(GroupActionTypes.AUTH_SOCIALS_SUCESS, props);

export const setCrntgrpRequest = (props) =>
  action(GroupActionTypes.SET_CRNT_GRP_REQUEST, props);

export const setCrntgrpFailed = (props) =>
  action(GroupActionTypes.SET_CRNT_GRP_FAILURE, props);

export const setCrntgrpSuccess = (props) =>
  action(GroupActionTypes.SET_CRNT_GRP_SUCESS, props);

export const getProfileRequest = (props) =>
  action(GroupActionTypes.GET_PROFILE_SETTING_REQUEST, props);

export const getProfileSuccess = (props) =>
  action(GroupActionTypes.GET_PROFILE_SETTING_SUCCESS, props);

export const getProfileFailure = (props) =>
  action(GroupActionTypes.GET_PROFILE_SETTING_FAILURE, props);

export const postProfileRequest = (props) =>
  action(GroupActionTypes.POST_PROFILE_SETTING_REQUEST, props);

export const postProfileSuccess = (props) =>
  action(GroupActionTypes.POST_PROFILE_SETTING_SUCCESS, props);

export const postProfileFailure = (props) =>
  action(GroupActionTypes.POST_PROFILE_SETTING_FAILURE, props);

export const settingsChangeColor = (props) =>
  action(GroupActionTypes.CHANGE_COLOR, props);

export const setColor = (props) => action(GroupActionTypes.SET_COLOR, props);
export const setThemeValue = (props) =>
  action(GroupActionTypes.SET_THEME_VALUES, props);

export const syncpostRequest = (props) =>
  action(GroupActionTypes.POST_SYNC_REQUEST, props);

export const syncpostSuccess = (props) =>
  action(GroupActionTypes.POST_SYNC_SUCCESS, props);

export const syncpostFailure = (props) =>
  action(GroupActionTypes.POST_SYNC_FAILURE, props);

export const clearfbPosts = (props) =>
  action(GroupActionTypes.CLEAR_FB_POSTS, props);


  export const settingLocationRequest = (req) =>
    action(LocationActionTypes.LOCATION_LIST_REQUEST, req);
  export const settingLocationSuccess = (res) =>
    action(LocationActionTypes.LOCATION_LIST_SUCCESS, res);
  export const settingLocationFailure = (err) =>
    action(LocationActionTypes.LOCATION_LIST_FAILURE, err);

  export const settingCountryRequest = (req) =>
    action(CountryActionTypes.COUNTRY_LIST_REQUEST, req);
  export const settingCountrySuccess = (res) =>
    action(CountryActionTypes.COUNTRY_LIST_SUCCESS, res);
  export const settingCountryFailure = (err) =>
    action(CountryActionTypes.COUNTRY_LIST_FAILURE, err);
   
  export const settingPlaceRequest = (req) =>
    action(PlaceActionTypes.PLACE_LIST_REQUEST, req);
  export const settingPlaceSuccess = (res) =>
    action(PlaceActionTypes.PLACE_LIST_SUCCESS, res);
  export const settingPlaceFailure = (err) =>
    action(PlaceActionTypes.PLACE_LIST_FAILURE, err);


  export const subLocationRequest = (req) => 
   action(subLocationActionTypes.SUB_LOCATION_LIST_REQUEST, req);
  export const subLocationSuccess = (res) =>
   action(subLocationActionTypes.SUB_LOCATION_LIST_SUCCESS, res);
  export const subLocationFailure = (err) =>
   action(subLocationActionTypes.SUB_LOCATION_LIST_FAILURE, err);

export const settingSelector = (state) => state.SettingReducer;
