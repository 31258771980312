import { call, put, takeEvery } from "redux-saga/effects";
import { BUActionTypes,RegisterActionTypes } from "./businessUser.model";
import { deactApi, getBuList,register_business_details } from "./bu.api";
import { bUListFailure, bUListSucess,registerBusinessSuccess,registerBusinessFailure, deactivateFailure, deactivateSuccess  } from "./businessUser.action";
import Swal from "sweetalert2";

function* BuListHandler(action) {
  try {
    const resposne = yield call(getBuList, action.payload);
    yield put(bUListSucess(resposne))
  } catch (error) {
    yield put(bUListFailure(error));
  }
}

export function* handleRegister(action) {
  try {
    console.log(action?.payload, "PAYLOADS");
    const response = yield call(register_business_details, action.payload);
    yield put(registerBusinessSuccess(response));
    Swal.fire({
      title: action?.payload?.arabicStatus ? "تم تحديث بيانات التسجيل" : "Register Data Updated",
      text: action?.payload?.arabicStatus ? "تم تحديث بياناتك" : "Your Data Updated",
      icon: "success",
    });
  } catch (error) {
    yield put(registerBusinessFailure(error));
    Swal.fire({
      title: action?.payload?.arabicStatus ? "تم تحديث بيانات التسجيل" : "Register Data Updated",
      text: action?.payload?.arabicStatus ? "لا يمكن معالجة تحديث التسجيل الخاص بك بسبب عدم اكتمال المعلومات." : "Your registration update couldn't be processed due to incomplete information.",
      icon: "error",
    });
  }
}
function* handleDeactivate(action){
  try {
    const response=yield call(deactApi, action.payload);
    yield put(deactivateSuccess(response));
    Swal.fire({
      title:action?.payload?.status==1 ? "Activated SuccessFully": "Deactivated successfully",
      text:action?.payload?.status==1 ? "Account Activated SuccessFully": "Account Deactivated successfully",
      icon: "success",
    });

  } catch (error) {
    yield put(deactivateFailure(error));
    Swal.fire({
      title:action?.payload?.status==1 ? "Activated Failed": "Deactivated Failed",
      text:action?.payload?.status==1 ? "Account Activated Failed": "Account Deactivated Failed",
      icon: "error",
    });
  }
}

export function* BUSaga() {
  yield takeEvery(BUActionTypes.USERLIST_REQUEST, BuListHandler);
  yield takeEvery(
    RegisterActionTypes.REGISTER_BUSINESS_REQUEST,
    handleRegister
  );
  yield takeEvery(BUActionTypes.DEACTIVATE_REQUEST, handleDeactivate)
}
