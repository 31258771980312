import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const getBuList = ({ page, limit, query }) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/business_user/user-lists?query="+query+"&page=" + page + "&limit=" + limit,
    Http.getAuthToken()
  );
};

export const register_business_details = (payload) => {
 
  const baseUrl = getBaseEndpointUrl();
  console.log(payload, "register_business_details");
  return Http.post(
    baseUrl + "/business_user/user_details",
    payload,
    Http.getFileAuthToken()
  );
};
export const deactApi=(payload)=>{
  const baseUrl=getBaseEndpointUrl();
  return Http.post(baseUrl+"/business_user/deactivate", payload, Http.getAuthToken());
}